<template>
    <div class="code_input">
        <input ref="firstInput" class="numeric login_input" maxlength="1" min="0" max="9" type="number" v-model="first">
        <input ref="secondInput" class="numeric login_input" maxlength="1" min="0" max="9" type="number" v-model="second">
        <input ref="thirdInput" class="numeric login_input" maxlength="1" min="0" max="9" type="number" v-model="third">
        <input ref="forthInput" class="numeric login_input" maxlength="1" min="0" max="9" type="number" v-model="forth">
    </div>
</template>
<script>
export default {
    props: ['callback'],
    data(){
        return {
            first: '',
            second: '', 
            third: '',
            forth: ''
        }
    },
    methods:{
      validate:function()
      {
        if (this.first.length>0 && this.second.length>0 && this.third.length>0 && this.forth.length>0)
        {
          let cod=this.first.toString()+this.second.toString()+this.third.toString()+this.forth.toString()
          this.callback(cod)
        }
        
      }
    },
    watch: {
    first: function () {
      this.validate()
      if (this.first.length>1)
      {
        this.first=''
      }
      this.$refs.secondInput.focus()
    },
    second: function () {
      this.validate()
      if (this.second.length>1)
      {
        this.second=''
      }
      this.$refs.thirdInput.focus()
    },
    third: function () {
      this.validate()
      if (this.third.length>1)
      {
        this.third=''
      }
      this.$refs.forthInput.focus()
    },
    forth: function () {
      if (this.forth.length>1)
      {
        this.forth=''
      }
      this.validate()
    }
    }
}
</script>
<style scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
        text-align: center;
    }
    .code_input{
        display: flex;
        justify-content: center;
    }
    .numeric{
        background: #FFF;
        height: 48px;
        width: 48px;
        border: 1px solid #AAABAD;
        box-sizing: border-box;
        border-radius: 8px;
        margin: 10px 6px;
    }
    
</style>