<template>
  <div class="password_wrapper">
    <input :type="type"  v-model="password"  :class="{ danger: isDanger }" placeholder="Пароль" @change="cb" class="password_input">
    <span v-if="isDanger">{{dangerMessage}}</span>
    <div @click="changeType" :class="'btn_change ' + status"></div>
  </div>
</template>
<script>
export default {
  props: ['callback'],
  data(){
    return { 
      isDanger:false,
      password:'',
      dangerMessage:'',
      type: 'password',
      status: 'hide'
    }
  },
  methods: {
    cb()
    {
      this.isDanger=false 
      if (this.password.length<7)
      {
      this.isDanger=true 
      this.dangerMessage="Пароль слишком короткий" 
      }
      this.callback(this.password)
    },
    changeType(){
      if(this.type == 'password') {
        this.type = 'text'
        this.status = 'visible'
      }
      else {
        this.type = 'password'
        this.status = 'hide'
      }
    }
  }
}
</script>
<style scoped>
.password_wrapper{
  position: relative;
}
.password_input{
  padding: 0px 12px;
  height: 48px;
  background: #FFF;
  border: 1px solid #AAABAD;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 10px 0px;
  width: 100%;
}
.btn_change{
  position: absolute;
  right: 10px;
  top: 28px;
  width: 21px;
  height: 12px;
}
.danger
{
  border:1px solid red;
}
span{ 
  font-size: 13px;
  color: #EE505A;
  margin: 4px 0px;
  text-align: left;
}
.hide{
  background: url('../../assets/img/Eye.svg');
}
.visible{
  background: url('../../assets/img/NoEye.svg');
  height: 21px;
  top: 24px;
}
</style>