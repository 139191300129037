<template>
	<div class="text-center">
		<v-dialog v-model="dialog" width="510">
			<template v-slot:activator="{ on, attrs }">
				<div v-bind="attrs" v-on="on" class="add">
					<Icon name="plus"/>
					<span v-if="type=='create'">Добавить базу номеров</span>
					<span v-if="type=='add'">Добавить номера</span>
				</div>
			</template>

			<div @click="dialog = false" class="close">
				<Icon name="close"/>
			</div>
			<v-card class="wrapper">
				<div class="modal_title">
					<span v-if="type=='create'">Добавить новую базу номеров</span>
					<span v-if="type=='add'">Добавить номера</span>
				</div>
				<div v-if="type=='create' && this.queue<1">
					<p class="modal_subtitle">Назовите базу номеров</p>
					<v-text-field v-model="basename" placeholder="Тестовая база номеров" :rules="rules"></v-text-field>
				</div>
				<div v-if="type=='create' && this.queue<1">
					<BaseParams :params="params" @updateParams="updateData"/>
				</div>
				<p class="subtitle">Загрузите номера телефонов</p>

		
				<v-tabs active-class="my_active_tab" background-color="fff" color="#FEE600" v-model="tab">
					<v-tabs-slider color="#FEE600"></v-tabs-slider>
					<v-tab class="my_tab_item" value="manual">Ввести вручную</v-tab>
					<v-tab class="my_tab_item" value="file">Загрузить файл</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item key="manual">
						<div class="textarea">
							<v-textarea
								class="my_textarea"
								name="input-7-1"
								rows="5"
								no-resize
								v-model="manual_phone"
								placeholder="Номера телефонов"
							></v-textarea>
						</div>

						<div class="rules">
							Номера должны быть в формате 7(0000)000-00-00, +7(000)000-00-00 , 8(000)000-00-00 или
							8(000)000-00-00 по одному номеру в строке
						</div>
					</v-tab-item>
					<v-tab-item key="file">
						<!--						<XlsDrop v-if="!this.phones" :callback="loadFile"/>-->
						<div v-if="!this.phones">
							<input
								type="file"
								accept=".csv"
								@change="showFile($event)"
								name="file"
								id="input__file"
								class="input input__file"
								ref="fileCsv"
							>
							<label for="input__file">
								<div class="choice_file">
									Выбрать файл
								</div>

							</label>
						</div>
						<!--            <FileDrop :callback="loadFile" />-->
						<pre>{{ dataTablePhone }}</pre>
						<div v-if="this.phones" class="textarea">
							<div class="count">{{ dataTablePhone.length }} номеров</div>
							<v-textarea
								class="my_textarea"
								name="input-7-1"
								no-resize
								rows="5"
								:value="dataTablePhone.map(row => row.phone )"
								placeholder="Номера телефонов"
							></v-textarea>

							<div class="rules">
								Номера должны быть в формате 7(0000)000-00-00, +7(000)000-00-00 , 8(000)000-00-00 или
								8(000)000-00-00 по одному номеру в строке
							</div>
						</div>
					</v-tab-item>
				</v-tabs-items>

				<div v-if="this.queue>0">Обработано {{ this.complete }}/{{ this.queue }}</div>
				<div v-if="this.queue<1" class="add add_modal" @click="saveBase">
					<span v-if="type=='create'">Добавить базу</span>
					<span v-if="type=='add'">Добавить номера</span>
				</div>
				<div class="rules">
					Добавляя базу номеров Вы подтверждаете факт получения Вами явного согласия от каждого абонента в
					загружаемой базе на получение рекламной информации в соответствии с ч. 1 и 2 ст. 18 Закона «О
					рекламе»
				</div>
			</v-card>
		</v-dialog>
		<!--		<BaseModal :show-modal="popup.addClients" width="1200" @close="closePopup()" :overlay="false">-->
		<CustomTableSaveCSV
			:value-table="testContext"
			@saveTable="baseTable"
			@close="closePopup()"
			:show-popup="popup.addClients"
		/>
		<!--		</BaseModal>-->
	</div>
</template>
<script>
import Icon from '../../SetIcon.vue'
// import XlsDrop from '../../XlsDrop.vue'
import BaseParams from './Form/BaseParams.vue'
// import BaseModal from "@/components/UI/BaseModal.vue";
import CustomTableSaveCSV from "@/components/Table/CustomTableSaveCSV.vue";
// import FileDrop from '../../FileDrop.vue'
export default {
	props: ['callback_refresh', 'type', 'guid', 'lead_type'],
	components: {
		Icon,
		// XlsDrop,
		BaseParams,
		// BaseModal,
		CustomTableSaveCSV
	},
	data() {
		return {
			dataTablePhone: [],
			popup: {
				addClients: false,
			},
			dialog: false,
			count: 0,
			queue: 0,
			complete: 0,
			check_complete: 0,
			phones: false,
			manual_phone: "",
			basename: "",
			params: {
				autodelete: false,
				day: 10
			},
			tab: 'manual',
			rules: [
				value => !!value || 'Обязательное поле',
				value => (value || '').length <= 20 || 'Max 20 символов'
			],
			testContext: [],
		}
	},
	methods: {
		baseTable(data) {
			this.dataTablePhone = data
			this.phones = true
		},
		closePopup() {
			this.popup.addClients = false
		},
		loadFile(obj, count) {
			this.phones = obj
			this.count = count
		},
		updateData(data) {
			this.params = data
		},
		partLoad(name, phones, i, id, type) {
			let part_phones = phones.slice(i, i + 1000)
			this.$http.post("/base/add",
			{
				name,
				params: this.params,
				id,
				type,
				phones: part_phones
			}, data => {
				this.check_complete += data.count
				id = data.id
				this.complete += part_phones.length
				if (part_phones.length < 1000) {
					this.complete = 0
					this.queue = 0
					this.manual_phone = ''
					this.phones = false

					this.callback_refresh()
					this.dialog = false

					this.check_complete = 0
				} else {
					this.partLoad(name, phones, i + 1000, id, type)
				}
			})
		},
		saveBase() {
			let phones = this.manual_phone
			if (this.tab == 1) phones = this.phones
			
			phones = phones.split(/\r\n|\r|\n/g);


			if (this.type == "create") {
				if (this.basename.length < 22 && this.basename.length > 0) {
					this.complete = 0
					this.queue = phones.length
					this.partLoad(this.basename, phones, 0, false, false)
				}
			} else {
				this.complete = 0
				this.queue = phones.length
				this.partLoad(this.basename, phones, 0, this.guid, this.lead_type)
			}
		},

		showFile(input) {
			console.log(input)

			let file
			if (input.target) file = input.target.files[0];
			else file = input.file

			const reader = new FileReader()
			console.log(file)
			reader.addEventListener("load", () => {
				this.testContext = reader.result
				this.renderTableFile()
				// input.reset();
			}, false)


			if (file) reader.readAsText(file);
		},
		renderTableFile() {

			this.testContext = this.testContext.split('\n')

			let test = [...this.testContext]
			this.testContext = []

			test.forEach((item) => {
				this.testContext.push(item.split(','))
			})

			this.popup.addClients = true
			console.log(this.testContext, 'this.testContext this.testContext')

			this.$refs.fileCsv.value = null;
		}
	}
}
</script>
<style scoped>
.choice_file {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #CBCBCB;
    cursor: pointer;
    margin-bottom: 30px;
    margin-top: 30px;
}

.input__file {
    width: 50px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.count {
    width: 100%;
    text-align: left;
    font-size: 13px;
    font-weight: 600;
    line-height: 24px;
}

.my_textarea {
    padding-top: 16px;
}

.my_tab_item {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    color: #808185;
    text-transform: none;
}

.my_tab_item:hover {
    color: #2B2D33 !important;
    background: none !important;
}

.my_active_tab {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    color: #2B2D33 !important;
}

.rules {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    color: #AAABAD;
    text-align: left;
    margin-bottom: 24px;
}

.add_modal {
    width: 100%;
    padding: 9px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 110%;
    color: #2B2D33;
}

.textarea {
    padding: 16px 0;
}

.subtitle {
    font-style: normal;
    text-align: left;
    font-weight: 700;
    font-size: 17px;
    line-height: 120%;
    margin-top: 24px;
    color: #404247;
}

.wrapper {
    padding: 32px;
}

.modal_subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    margin-bottom: 6px;
    text-align: left;
    color: #404247;
}

.add {
    border: none;
    padding: 12px 20px 12px 22px;
    background: #FEE600 !important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #2B2D33;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add .icon {
    margin-right: 11px;
}

.modal_title {
    padding: 0 !important;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2B2D33;
}
</style>