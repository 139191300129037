<template>
  <div class="render">
    <div class="top">
      <h1>Тарифы</h1>
    </div>
    <div class="content_table">
      <Table
        :server="true"
        :hideselect="true"
        :loading="loading"
        :headers="headers"
        :data="data"
        :perPage="perPage"
        :pageCount_server="2"
        :callback_refresh="renderRates"
      />
    </div>
  </div>
</template>
<script lang="js">
import Table from '../components/Table/DataTable.vue'
export default {
  name: 'RatesView',
  components: {
    Table
  },
  data() {
    return {
      data: [],
      loading: true,
      operators: ['МТС', 'Ростелеком', 'Yota', 'Мегафон', 'Tele2', 'Билайн', 'Мотив', 'КТК-Телеком', 'Прочие операторы'],
      headers: [
        {text: 'Оператор', value: 'operator', sortable: false },
        {text: 'Количество', value: 'limit', sortable: false},
        {text: 'Стоимость', value: 'price', sortable: false }
      ],
      user: this.$store.getters['user/getUserFields'],
      perPage: 10
    }
  },
  created() {

  },
  methods: {
    renderRates() {
      this.$http.post("/rates/list", {}, result => {
        this.data = result
        this.loading = false
      })
    }
  }
}
</script>
<style lang="sass" scoped>
.top
  margin-bottom: 30px
</style>