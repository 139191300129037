<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="510" >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="add">
          <Icon name="plus"/>
          <span>Добавить имя</span>
        </div>
      </template>

      <div @click="dialog = false" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
        <div class="modal_title">
          <span>Создать</span>
        </div>
        <div>
          <p class="modal_subtitle">Имя</p>
          <v-text-field v-model="name" placeholder="my-name.ru" :rules="rules"></v-text-field>
        </div>
        <div>
          <p class="modal_subtitle">Документы</p>
          <Filedrop />
        </div>
        <div>
          <p class="modal_subtitle">Комментарий</p>
          <v-text-field v-model="comment" placeholder="Если необходимо"></v-text-field>
        </div>
        <div>
          <p class="modal_subtitle">Операторы</p>
          <v-select v-model="operators" :items="items" multiple></v-select>
        </div>

        <div class="add add_modal" @click="saveAlpha">
          <span>Отправить на согласование</span>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../../SetIcon.vue'
import Filedrop from '../../FileDrop.vue'
export default {
  props: ['callback_refresh','type','createEvent','show_dialog'],
  components: {
    Icon,
    Filedrop
  },
  methods: {
    updateData(data) {
      this.params = data
    },
    partLoad(name, phones, i, id, type) {
      let part_phones = phones.slice(i, i + 1000)
      this.$http.post("/alpha/add", {
        name,
        params: this.params,
        unique: this.unique,
        id,
        type,
        phones: part_phones
      }, data => {
        this.check_complete += data.count
        id = data.id
        this.complete += part_phones.length
        if (part_phones.length < 1000) {
          this.complete = 0
          this.queue = 0
          this.manual_phone = ''
          this.phones = false
          this.callback_refresh()
          this.dialog = false
          this.check_complete = 0
        }
        else this.partLoad(name, phones, i + 1000, id, type)
      })
    },
    saveAlpha(){
      this.$http.post("/alpha/create", {
        name: this.name,
        comment: this.comment,
        documents: this.documents,
        operators: this.operators
      }, () => {
        this.$emit('createEvent')
      })
    }
  },
  data(){
    return {
      items: ['MTS', 'Tele2', 'Beeline', 'Megafon', 'Rostelecom'],
      name: '',
      documents: '',
      operators: [],
      comment: '',
      dialog: false,
      count: 0,
      queue: 0,
      complete: 0,
      unique: 0,
      check_complete: 0,
      edit_popup: false,
      manual_phone: "",
      rules: [
        value => !!value || 'Обязательное поле',
        value => (value || '').length <= 20 || 'Max 20 символов'
      ]
    }
  },
  watch: {
    show_dialog: function (val) {
      console.log(666, this.edit_alpha)
      this.dialog = val
      if (val) {
        this.alphaName = this.edit_alpha.name
        this.operators = this.edit_alpha.operators
        this.comment = this.edit_alpha.comment
        this.status = 'wait'
      }
    },
  }
}
</script>
<style scoped>

.add_modal{
  width: 100%;
  padding: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
}
.wrapper{
  padding: 32px;
}
.modal_subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  margin-bottom: 6px;
  text-align: left;
  color: #404247;
}
.add{
  border: none;
  padding: 12px 20px 12px 22px;
  background: #FEE600!important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #2B2D33;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add .icon{
  margin-right: 11px;
}
.modal_title{
  padding: 0!important;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #2B2D33;
}
</style>