import {getters} from './getters'
import {actions} from './actions'
import {mutations} from './mutations'

const state = {
    user: {},
    login: "wait",
    selected: [],
    check_policy: false
};

const namespaced = true

export const user = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}