<template>
	<div class="filters">
		<div class="filter">
			<div class="filter_item filter_range" v-if="visible.date">
				<div class="wrap_titler">
					<div class="titler">Выберите период</div>
					<div v-if="filters.rangePicker.length" class="clear" @click="filters.rangePicker=[]">
						<Icon name="close"/>
					</div>
				</div>
				<v-menu
					:close-on-content-click="false"
					:nudge-right="40"
					transition="scale-transition"
					offset-y
					min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<div class="rel" v-bind="attrs" v-on="on">
							<v-text-field
								v-model="dateRangeText"
								readonly
								class="input_custom input_range"
								hide-details
								height="40"
							></v-text-field>
							<Icon class="abs" name="calendar"/>
						</div>
					</template>
					<v-date-picker
						v-model="filters.rangePicker"
						range
						locale="ru"
						no-title
						first-day-of-week="1"
						color="#000"
						event-color="#00A5BC"
					></v-date-picker>
				</v-menu>
			</div>

			<div class="filter_item" v-if="visible.calls">
				<div class="wrap_titler">
					<div class="titler">Выберите обзвон</div>
					<div v-if="filters.current_call" class="clear" @click="filters.current_call=null">
						<Icon name="close"/>
					</div>
				</div>
				<v-combobox
					class="input_custom"
					v-model="filters.current_call"
					:items="visible.calls"
					hide-selected
					solo
					no-data-text="Обзвонов нет"
					color="#FEE600"
					height="40"
					hide-details
					error-count="0"
					width="450"
				>
				</v-combobox>
			</div>

			<div class="filter_item" v-if="visible.phone">
				<div class="wrap_titler">
					<div class="titler">Поиск по номеру</div>
					<div v-if="filters.search" class="clear" @click="filters.search=null">
						<Icon name="close"/>
					</div>
				</div>
				<div class="search rel">
					<v-text-field
						v-model="filters.search"
						type="tel"
						class="input_custom"
						placeholder="Введите номер"
						error-count="0"
						height="40"
						v-maska="maskaFilter"
						:hide-details="true"
					></v-text-field>
					<div class="abs search_btn">
						<Icon name="search"/>
					</div>

				</div>
			</div>

			<div class="filter_item" v-if="visible.text">
				<div class="wrap_titler">
					<div class="titler">Поиск по фразе</div>
					<div v-if="filters.text" class="clear" @click="filters.text=null">
						<Icon name="close"/>
					</div>
				</div>
				<div class="search rel">
					<v-text-field
						v-model="filters.text"
						type="text"
						class="input_custom"
						placeholder="Введите фразу"
						error-count="0"
						height="40"
						v-maska="''"
						:hide-details="true"
					></v-text-field>
					<div class="abs search_btn">
						<Icon name="search"/>
					</div>

				</div>
			</div>



			<div class="filter_item" v-if="visible.nickname">
				<div class="wrap_titler">
					<div class="titler">Поиск по псевдониму</div>
					<div v-if="filters.searchNickname" class="clear" @click="filters.searchNickname=null">
						<Icon name="close"/>
					</div>
				</div>
				<div class="search rel">
					<v-text-field
						v-model="filters.searchNickname"
						type="tel"
						class="input_custom"
						placeholder="Введите псевдоним"
						error-count="0"
						height="40"
						v-maska="''"
						:hide-details="true"
					></v-text-field>
					<div class="abs search_btn">
						<Icon name="search"/>
					</div>

				</div>
			</div>


			<div class="apply" @click="filterUpdate()">
				Применить
			</div>
		</div>

	</div>
</template>
<script>
import Icon from './SetIcon.vue'

export default {
	components: {
		Icon
	},
	computed: {
		dateRangeText() {
			if (this.filters.rangePicker.length) return this.filters.rangePicker.join(' — ')
			return []
		},
		maskaFilter() {
			return this.visible.maska ? this.visible.maska : '+7 (###) ###-##-##'
		}
	},
	methods: {
		validateData() {
			if (this.filters.rangePicker.length > 0) {
				if (this.filters.rangePicker.length == 1) {
					this.filters.rangePicker[1] = this.filters.rangePicker[0]
				}

				let start = new Date(this.filters.rangePicker[0])
				let end = new Date(this.filters.rangePicker[1])
				if (end.getTime() < start.getTime()) {

					this.filters.rangePicker[1] = this.filters.rangePicker[0]
				}
			}

		},
		doSearch() {
			this.$http.post("/analytics/number", {number: this.filters.search}, data => {
				this.callback(data)
			})
		},

		today() {
			return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
		},
		filterUpdate() {
			this.validateData()
			let filters = {
				select: this.filters.current_call,
				date: this.filters.rangePicker,
				search: this.filters.search,
				searchNickname: this.filters.searchNickname,
				text: this.filters.text
			}
			this.$emit('updateFilter', filters)
		},

	},
	mounted() {
		this.filterUpdate()
	},
	data() {
		let dateRange = []
		if (this.visible.date == "today") {
			dateRange = [this.today(), this.today()]
		}
		return {
			filters: {
				startPicker: '',
				finishPicker: '',
				rangePicker: dateRange,
				search: '',
				searchNickname: '',
				current_call: null,
				text: ''
			}
		}
	},
	props: [
		'visible', 'callback'
	]
}
</script>
<style scoped>
.wrap_titler {
	display: flex;
	justify-content: space-between;
}

.clear {
	cursor: pointer;
}

.ranger {
	position: absolute;
}

.rel {
	position: relative;
}

.input_range {
	min-width: 250px;
}

.abs {
	position: absolute;
	top: 13px;
	right: 13px;
	cursor: pointer;
}

.search {
	position: relative;
}

.search_btn {
	right: 10px;
	top: 10px;
}

.apply {
	background: #FFF;
	border: 1px solid #FED500;
	border-radius: 8px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	color: #2B2D33;
	padding: 10px 20px;
	margin: 24px 30px 0 30px;
	max-height: 40px;
	cursor: pointer;
}

.btn_sort {
	display: flex;
	align-items: center;
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 120%;
	color: #00809C;
}

.btn_sort .icon {
	margin-left: 8px;
}

.filter {
	display: flex;
	position: relative;
}

.calls {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.filters {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #FFF4E8;
	padding: 22px 32px;
	border-radius: 8px 8px 0 0;
}

.titler {
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	color: #404247;
	text-align: left;
	margin-bottom: 6px;
}

.filter_item {
	position: relative;
	margin-right: 8px;
	display: flex;
	flex-direction: column;
}

@media (max-width: 800px) {
	.filters {
		justify-content: center;
	}

	.filter {
		flex-direction: column;
	}

	.filter_item {
		margin-top: 10px;
		margin-right: 0;
		margin-left: 0;
	}
}
</style>
