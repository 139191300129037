<template>
  <DefaultView title-page="Отчеты">
    <FilterReports :page_type="'reports'" :selects="selects_load" :default="selects_load[0].name"
                   :callback="renderTargets"/>
    <Table
        :server="false"
        :hideselect="true"
        :loading="loading.reports"
        :items="table.action_items"
        :headers="table.headers"
        :data="table.data"
        @open="openReports"
        @change="changeReports"
        @delete="deleteReports"
    />
  </DefaultView>
</template>
<script>
import Table from '../components/Table/DataTable.vue'
import FilterReports from '../components/Filter/ReportsFilter'
import DefaultView from "@/components/ContainersView/DefaultView.vue";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(ArcElement, CategoryScale)
export default {
  components: {
    Table,
    FilterReports,
    DefaultView,
  },
  props: {},

  data() {
    return {
      popup: {
        report: false
      },
      valuePopupReport: {
        id: null,
        name: '',
        type: '',
        date_range: ''
      },
      loading: {
        reports: false
      },
      selects_load: [
        {name: 'По альфам', type: 'alphas'},
        {name: 'По email-альфам', type: 'email-alphas'},
        {name: 'По каналам', type: 'channels'},
        {name: 'По пользователям', type: 'users'},
      ],
      table: {
        data: [],
        headers: [
          {text: 'Альфа', value: 'alpha', sortable: false},
          {text: 'Кол-во смс', value: 'sms_count', sortable: true},
          {text: 'Доставлено', value: 'delivered', sortable: true},
          {text: 'Средняя стоимость', value: 'avg_price', sortable: true},
        ],
      },
      rules: [
        value => !!value || 'Обязательное поле',
        value => (value || '').length <= 20 || 'Max 20 символов'
      ],
      types: [
        {name: 'По базам', type: 'bases'},
        {name: 'По альфам', type: 'alphas'},
        {name: 'По рассылкам', type: 'senders'}
      ],
    }
  },
  computed: {},
  methods: {
    save() {
      this.closePopup()
    },
    openReports(row) {
      this.$router.push(`/report/${row.id}`)
    },
    closePopup() {
      this.popup.report = false
      this.valuePopupReport = {
        id: null,
        name: '',
        type: '',
        date_range: ''
      }
    },
    changeReports(row) {
      console.log(row.id)
      // тут делаем запрос на бек для получения данных по настройке отчета
      // или присваиваем значения попапу с таблицы (нужно найти по ид и потом еще найти тип по названию и присвоеть его type)
      // открываем попап
      // дальше отправляем с ид на бек лдя изменения

    },
    deleteReports(row) {
      console.log(row.id)
      // запрос на удаление по ид
    },
    renderTargets(callback) {
      let url
      switch (callback.filterScript) {
        case 'По альфам':
          url = '/admin/reports/alphas'
          break;
        case 'По email-альфам':
          url = '/admin/reports/email_alphas'
          break;
        case 'По каналам':
          url = '/admin/reports/channels'
          break;
        case 'По пользователям':
          url = '/admin/reports/users'
          break;
      }
      this.setHeaders(callback.filterScript)
      this.render(url)
    },
    render(url) {
      this.loading.reports = true
      this.table.data = []
      this.$http.post(url, {}, data => {
        this.table.data = data
        this.loading.reports = false
      })
    },
    setHeaders(type) {
      switch (type) {
        case 'По альфам':
          this.table.headers = [
            {text: 'Альфа', value: 'alpha', sortable: false},
            {text: 'Кол-во смс', value: 'sms_count', sortable: true},
            {text: 'Доставлено', value: 'delivered', sortable: true},
            {text: 'Средняя стоимость', value: 'avg_price', sortable: true},
          ]
          break;
        case 'По email-альфам':
          this.table.headers = [
            {text: 'Альфа', value: 'alpha', sortable: false},
            {text: 'Кол-во emails', value: 'emails_count', sortable: true},
            {text: 'Сумма', value: 'sum', sortable: true},
          ]
          break;
        case 'По каналам':
          this.table.headers = [
            {text: 'Канал', value: 'channel', sortable: false},
            {text: 'Кол-во смс', value: 'sms_count', sortable: true},
            {text: 'Доставлено', value: 'delivered_count', sortable: true},
            {text: 'Всего (руб.)', value: 'total_sum', sortable: true},
            {text: 'Расходы (руб.)', value: 'expense', sortable: true},
            {text: 'Доход (руб.)', value: 'income', sortable: true},
            {text: 'Кол-во кликов', value: 'clicks_count', sortable: true},
            {text: 'Кол-во редиректов', value: 'redirect_count', sortable: true},
            {text: 'Процент кликов', value: 'click_percentage', sortable: true},
          ]
          break;
        case 'По пользователям':
          this.table.headers = [
            {text: 'Телефон', value: 'phone', sortable: false},
            {text: 'Кол-во смс', value: 'sms_count', sortable: true},
            {text: 'Кол-во emails', value: 'emails_count', sortable: true},
            {text: 'Доставлено смс', value: 'sms_delivered', sortable: true},
            {text: 'Сумма за смс', value: 'sms_sum', sortable: true},
            {text: 'Сумма emails', value: 'emails_sum', sortable: true},
            {text: 'Кол-во кликов', value: 'clicks_count', sortable: true},
          ]
          break;
      }
    }
  },
  beforeMount() {
    this.render('/admin/reports/alphas')
  },
}
</script>
<style scoped>
.content_table {
  margin-top: 24px;
}

.blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.add {
  border: none;
  padding: 12px 20px 12px 22px;
  background: #FEE600 !important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #2B2D33;
  cursor: pointer;
  display: flex;
  margin-top: 24px;
  justify-content: center;
}

.add .icon {
  margin-right: 11px;
}

.item_tab {
  text-transform: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 120%;
  color: #808185;
}

.my_active_tab {
  color: #2B2D33;
}

.my_toolbar {
  margin-top: 16px;
}

.num_big {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  color: #404247;
  max-width: 75px;
  position: absolute;
  top: 55px;
  width: 75px;
  left: 51px;
}

.block_item {
  padding: 16px 24px;
  border-radius: 8px;

  width: 265px;
  margin-bottom: 36px;
}

.block_item:nth-child(odd) {
  margin-right: 20px;
}

.small_blocks {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: center;
}

.block_item .heading {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 120%;
  color: #404247;
  margin-bottom: 12px;
  text-align: left;
}

.block_item .num {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  color: #404247;
  text-align: left;
}

.my_red .num {
  color: #EE505A;
}

.my_gray {
  background: #f8f8f8;
}

.my_red {
  background: #FDEEEE;
}

.my_green {
  background: #E8F7F4;
}

.block {
  display: flex;
  flex-wrap: wrap;
  padding: 32px 24px;
  justify-content: center;
  max-width: 600px;
}

.big_block {
  background: #E8F7F4;
  border-radius: 8px;
  display: flex;
  padding: 24px 16px;
  min-width: 500px;
  max-height: 230px;
}

.right {
  margin-left: 32px;
}

.heading {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  text-align: left;
  line-height: 130%;
  color: #404247;
}

.robot, .client, .silence {
  text-align: left;
  position: relative;
  margin-top: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  padding-left: 25px;
  line-height: 120%;
  color: #404247;
}

.robot:before {
  display: block;
  content: '';
  width: 18px;
  height: 18px;
  background: #FAA373;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.client:before {
  display: block;
  content: '';
  width: 18px;
  height: 18px;
  background: #57B6ED;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.silence:before {
  display: block;
  content: '';
  width: 18px;
  height: 18px;
  background: #9A99FB;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.content {
  height: 100%;
  display: flex;
  position: relative;
  z-index: 1000;
}

.empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 500px;
}

.subtitle {
  max-width: 450px;
  margin-bottom: 32px;
}

.left {
  position: relative;
}

@media (max-width: 1000px) {
  .filters {
    flex-direction: column;
  }
}

@media (max-width: 650px) {
  .top {
    justify-content: center;
  }

  .big_block {
    flex-direction: column;
    max-height: initial;
    min-width: initial;
  }

  .num_big {
    left: calc(50% - 36px);
  }

  .right {
    margin-left: 0;
  }
}

@media (max-width: 620px) {
  .block_item:nth-child(odd) {
    margin-right: 0;
  }

  .small_blocks {
    flex-direction: column;
  }
}

@media (max-width: 400px) {
  h1 {
    margin-top: 15px;
  }
}
</style>