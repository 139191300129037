<template>
	<DefaultView title-page="Шаблоны">
		<FilterCustom @updateFilter="onUpdateFilter" :visible="{phone:true,maska:'+7 ### ###-##-##'}"/>
		<BaseTable
				:loading="loading"
				:headers="table.headers"
				:data="table.filterData"
		>
			<template v-slot:status="{ item }">
				<!--				<v-select-->
				<!--						class="select_table"-->
				<!--						style="max-width: 300px;"-->
				<!--						v-model="item.status"-->
				<!--						:items="[{value: 'waiting', label: 'Ожидает проверки'},-->
				<!--										{value: 'active', label: 'Одобрено'},-->
				<!--										{value: 'unactive', label: 'Отклонено'},]"-->
				<!--						item-text="label"-->
				<!--						item-value="value"-->
				<!--						dense-->
				<!--						hide-details-->
				<!--						variant="underlined"-->
				<!--				>-->
				<!--					<template v-slot:selection="{item}">-->
				<!--						<div :class="colorSelect[item.value]">{{ item.label }}</div>-->
				<!--					</template>-->
				<!--				</v-select>-->
				<SelectTable :value="item.status"
						:options="optionsSelectStatus"
						@update="(val) => {item.status = val}"
				/>
			</template>
			<!--			<template v-slot:action>-->
			<!--				<v-list-item @click="popup.balance = true">-->
			<!--					<v-list-item-title>-->
			<!--						Пополнить-->
			<!--					</v-list-item-title>-->
			<!--				</v-list-item>-->
			<!--				<v-list-item @click="popup.inform = true">-->
			<!--					<v-list-item-title>-->
			<!--						Юр.инфо-->
			<!--					</v-list-item-title>-->
			<!--				</v-list-item>-->
			<!--			</template>-->
		</BaseTable>
<!--		<pre>{{ table.data }}</pre>-->
	</DefaultView>
</template>


<script>
import DefaultView from "@/components/ContainersView/DefaultView.vue";
import FilterCustom from '../../components/FilterMy.vue'
import BaseTable from "@/components/UI/BaseTable.vue";
import SelectTable from "@/components/UI/SelectTable.vue";

export default {
	name: "TempaltesAdminView",
	components: {
		DefaultView,
		FilterCustom,
		BaseTable,
		SelectTable,
	},
	data() {
		return {
			optionsSelectStatus: [
				{value: 'waiting', label: 'Ожидает проверки'},
				{value: 'active', label: 'Одобрено'},
				{value: 'unactive', label: 'Отклонено'}
			],
			colorSelect: {
				active: 'status_success',
				waiting: 'status_wait',
				unactive: 'status_stop'
			},
			table: {
				data: [],
				filterData: [],
				headers: [
					{text: 'Телефон', value: 'phone', sortable: false},
					{text: 'Название', value: 'name', sortable: false},
					{text: 'Текст', value: 'text', sortable: false},
					{text: 'Операторы', value: 'operator', sortable: false},
					{text: 'Статус', value: 'status', width: 350, sortable: false},
					// {text: 'Действия', value: 'action_menu', sortable: false}
				]
			},
			loading: true,
			filters_data_users: false
		}
	},
	methods: {
		render() {
			this.table.data = [
				{
					phone: '34444444444',
					name: 'сервис тест1',
					text: 'купи купи',
					operator: '',
					status: 'waiting',
				}, {
					phone: '34444444444',
					name: 'мой ска шаблон',
					text: 'бла бла текст шаблона',
					operator: '',
					status: 'active',
				}, {
					phone: '34444444444',
					name: 'мой ска шаблон',
					text: 'бла бла текст шаблона',
					operator: '',
					status: 'unactive',
				},

			]
			this.filterDataUsers()
			this.loading = false
		},
		onUpdateFilter(new_filter) {
			this.filters_data_users = new_filter
			this.filterDataUsers()
		},
		filterDataUsers() {
			let newData = this.table.data
			if (this.filters_data_users.search) {
				newData = newData.filter(user => user.phone.includes(this.filters_data_users.search))
			}
			if (this.filters_data_users.searchNickname) {
				newData = newData.filter(user => {
					if (user.nickname) {
						return user.nickname.includes(this.filters_data_users.searchNickname)
					} else {
						return false
					}
				})
			}
			// console.log(newData)
			this.table.filterData = newData
		},
	},
	mounted() {
		this.render()
	}
}
</script>

<style scoped>

</style>