import { render, staticRenderFns } from "./CabinetView.vue?vue&type=template&id=7e222741&scoped=true"
import script from "./CabinetView.vue?vue&type=script&lang=js"
export * from "./CabinetView.vue?vue&type=script&lang=js"
import style0 from "./CabinetView.vue?vue&type=style&index=0&id=7e222741&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e222741",
  null
  
)

export default component.exports