<template>
	<div class="top_header">
		<div class="left">
			<v-img class="logo" @click="$router.push('/')" src="../assets/img/logo-mobile.svg" alt="logo"></v-img>
		</div>
		<Menu />
		<div class="right">
			<div class="balance top" v-if="$store.getters['user/getRole'] === 'user'">
				<Icon name="wallet"/>
				<div class="text">Баланс:</div>
				<div class="sum">{{ this.$store.getters['user/getBalance'] }} ₽</div>
				<div class="replenishment" :class="'top'" @click="openBalanceAdd">Пополнить</div>
				<Balance :dialogstate="showBalanceAdd" :dialogclose="closeBalanceAdd"/>
			</div>
			<div class="notification" :class="{'active':notifications_active}">
				<v-menu min-width="200" max-width="250" left nudge-left="10">
					<template v-slot:activator="{ on, attrs }">
						<div @click="getNotifications" v-bind="attrs" v-on="on">
							<Icon name="bell"/>
						</div>
					</template>
					<v-list v-if="notifications_window">
						<v-list-item class="notif_item" v-for="(item, index) in notifications" :key="index">
							<Icon :name="item.icon"/>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
			<div class="account top">
				<v-menu
						v-model="showMenu"
						bottom
						z-index=9999
						v-bind:balance="'url'"
						:offset-y="true"
						style="max-width: 600px;"
				>
					<template v-slot:activator="{ on, attrs}">
						<div class="login_box" v-bind="attrs" v-on="on">
							<Icon name="user"/>
							<div class="name">{{ $store.getters['user/getName'] }}</div>
							<Icon name="dropdown"/>
						</div>
					</template>
					<v-list>
						<span v-for="(item, index) in profile_menu" :key="index">
							<v-list-item v-if="checkRole(item.role)" @click="routerUser(item.namePath)">
								<Icon :name="item.icon"/>
								<v-list-item-title>
									{{ item.title }}
								</v-list-item-title>
							</v-list-item>
							<v-spacer/>
						</span>
					</v-list>
				</v-menu>
				<Alert v-if="!alert.hidden" @click="alert.hidden = true" :obj="alert" :hidden="false"/>
			</div>
		</div>
	</div>
</template>
<script>
import Icon from './SetIcon.vue'
import Balance from './Modal/Cabinet/BalanceAdd.vue'
import Alert from '../components/custom/CustomAlert.vue'
import Menu from '../components/TopMenu.vue'

export default {
	components: {
		Icon, Balance, Alert, Menu
	},
	emits: ['drawer'],
	methods: {
		checkRole(role){
			if (role[0] === 'all') return true
			return role.includes(this.$store.getters['user/getRole'])
		},
		openBalanceAdd() {
			this.showBalanceAdd = true;
		},
		closeBalanceAdd() {
			this.showBalanceAdd = false
		},
		router(path) {
			this.$router.push(path)
		},
		routerUser(namePath) {
			if (namePath === 'exit') this.$store.commit('user/logoutUser')
			else this.$router.push(namePath)
		},
		getNotifications() {
			let self = this
			this.$http.post("/cabinet/get_notifications", {}, result => {
				self.notifications = result
				self.notifications_active = false
				if (result.length == 0) self.notifications_window = false
			})
		}
	},
	data() {
		return {
			showBalanceAdd: false,
			showSuccessBalance: false,
			alert: {
				// inform, alert, green_ok, attention, blue_inform
				type: 'alert',
				heading: 'Neutral notification',
				text: 'Subtitle text or explanation goes here',
				hidden: false
			},
			showMenu: false,
			balance: -100,
			profile: '+7 111 111-11-11',
			profile_menu: [
				{title: 'Профиль', icon: 'user', namePath: 'account', role: ['user']},
				{title: 'Тарифы', icon: 'coins', namePath: 'rates', role: ['user']},
				{title: 'Документы', icon: 'docs', namePath: 'documents', role: ['user']},
				{title: 'Поддержка', icon: 'support', namePath: 'support', role: ['user']},
				{title: 'Справка', icon: 'library', namePath: 'reference', role: ['user']},
				{title: 'Выход', icon: 'exit', namePath: 'exit', role: ['all']}
			],
			notifications: [
				{title: 'Закончилиль средства на балансе', icon: 'alert'},
				{title: 'Обзвон 324523 завершён', icon: 'call_ok'},
				{title: 'Обзвон 43232 остановлен из-за нехватки средств', icon: 'call_bad'},
				{
					title: 'с 12 по 15 июня будут проводиться технические работы. Интеграции с tilda будут недоступны',
					icon: 'inform'
				}
			],
			notifications_active: false,
			notifications_window: false
		}
	},
	computed: {},
	mounted() {
		let _this = this
		setInterval(() => {
			let self = _this
			if(this.$store.getters['user/getRole'] === 'user'){
				this.$http.post("/cabinet/get_user_state", {}, result => {
					self.notifications_active = result.count_notifications > 0
					if (result.count_notifications > 0) self.notifications_window = true
					if (result.balance) {
						self.$store.commit('user/setBalance', result.balance)
						console.log(91, this)
					}
				})
			}
		}, 5000)
	}
}
</script>
<style scoped>
.icon {
    align-items: center;
}

.v-sheet div {
    cursor: pointer;
}

.logo {
    width: 140px;
    cursor: none;
}

.left {
    display: flex;
    align-items: center;
}

.notif_item {
    padding: 8px 15px;
}

.v-list-item__title {
    text-align: left !important;
    margin-left: 11px;
}

.login_box {
    display: flex;
    align-items: center;
}

.top_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 25px 12px 20px;
    position: relative;
}

.right {
    display: flex;
}

.balance {
    display: flex;
    align-items: center;
}

.dropdown {
    background: url('../assets/img/dropdown.svg');
    width: 12px;
    margin-left: 7px;
    cursor: pointer;
    margin: 8px;
    height: 8px;
}

.notifications_unactive:after {
    display: none !important;
}

.text {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    color: #2B2D33;
    margin-left: 7px;
}

.sum {
    font-weight: 700;
    font-size: 15px;
    line-height: 120%;
    align-items: center;
    text-align: center;
    color: #2B2D33;
    margin-left: 4px;
}

.notification {
    margin-right: 32px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.notification.active::after {
    position: absolute;
    top: 7px;
    border-radius: 50%;
    right: 0;
    width: 8px;
    height: 8px;
    content: '';
    display: block;
    background: #EE505A;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
}

.account {
    display: flex;
    align-items: center;
}

.account .icon {
    margin: 8px;
}

.replenishment {
    font-weight: 600;
    font-size: 15px;
    line-height: 110%;
    padding: 7px 17px;
    align-items: center;
    text-align: center;
    color: #2B2D33;
    background: #FFFFFF;
    border: 1px solid #FEE600;
    box-sizing: border-box;
    border-radius: 8px;
    margin-left: 16px;
    margin-right: 35px;
    cursor: pointer;
}
</style>
