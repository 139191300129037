<template>
  <div class="render">
    <div class="top">
      <h1>Документы</h1>
    </div>
    <div class="content_table">
      <Table
        :server="false"
        :hideselect="true"
        :loading="false"
        :items="action_items"
        :headers="headers"
        :data="demo"
        :perPage="perPage"
        :callback_refresh="renderDocs"
        @download="downloadEmit"
      />
    </div>
  </div>
</template>

<script lang="js">
import Table from '../components/Table/DataTable.vue'
export default {
  name: 'DocumentsView',
  components: {
    Table
  },
  mixins: [],
  props: [],
  data() {
    return {
      data: true,
      edit_popup: false,
      headers: [
        {text: 'Название', value: 'name', sortable: false},
        {text: 'Действия', value: 'actions', width: 100, sortable: false}
      ],
      action_items: [
        {title: 'Скачать', action: 'download'}
      ],
      edit_tag: {
        id: 0,
        item: {}
      },
      demo: [
        {
          name: 'Политика конфиденциальности'
        },
        {
          name: 'Договор об оказании услуг'
        },
        {
          name: 'Сертификаты'
        }
      ],
      perPage: 10,
    }
  },
  computed: {},
  watch: {},
  created() {
    
  },
  methods: {
    downloadEmit(){

    },
    renderDocs() {
      console.log('render')
    }
  }
}
</script>
<style lang="sass" scoped>
.top
  margin-bottom: 30px
</style>