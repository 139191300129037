<template>
  <DefaultView title-page="Цены">
    <div class="alert_modal" :class="{ active: showAlert }">
      <AlertModal :obj="AlertData"/>
    </div>
    <template v-slot:right-title>
      <BigButton text-btn="Добавить" icon-btn="plus" @click="popup.price = true"/>
    </template>
    <BaseTable
        :loading="loading"
        :headers="table.headers"
        :data="table.data"
    >
      <template v-slot:action="{item}">
        <v-list-item @click="changePrice(item)">
          <v-list-item-title>
            Изменить
          </v-list-item-title>
        </v-list-item>
        <PopconfirmButton @yes="deletePrice(item)">
          <v-list-item>
            <v-list-item-title>
              Удалить
            </v-list-item-title>
          </v-list-item>
        </PopconfirmButton>
      </template>
    </BaseTable>
    <BaseModal
        :show-modal="popup.price"
        @close="closePopup"
        @clickBtn="save"
        :title="valuePopupPrice._id ? 'Редактировать цену' : 'Добавить цену'"
    >
      <WrapperInputs title-inputs="Оператор">
        <v-select
            v-model="valuePopupPrice.operator"
            :items="operators"
            height="44"
        ></v-select>
      </WrapperInputs>
      <WrapperInputs title-inputs="Лимит">
        <v-text-field
            v-model="valuePopupPrice.limit"
            placeholder="Лимит"
            type="number"
        />
      </WrapperInputs>
      <WrapperInputs title-inputs="Цена">
        <v-text-field
            v-model="valuePopupPrice.price"
            placeholder="Цена"
            type="number"
        />
      </WrapperInputs>
    </BaseModal>
  </DefaultView>
</template>

<script>
import DefaultView from "@/components/ContainersView/DefaultView.vue"
import BaseModal from "@/components/UI/BaseModal.vue"
import BaseTable from "@/components/UI/BaseTable.vue"
import BigButton from "@/components/UI/BigButton.vue"
import PopconfirmButton from "@/components/UI/PopconfirmButton.vue"
import WrapperInputs from "@/components/UI/WrapperInputs.vue"
import AlertModal from "@/components/Modal/AlertModal.vue";

export default {
  name: "PriceAdminView",
  components: {
    AlertModal,
    DefaultView,
    BaseTable,
    BaseModal,
    BigButton,
    WrapperInputs,
    PopconfirmButton
  },
  data() {
    return {
      optionsSelectStatus: [
        {value: 'changePrice', label: 'Редактировать'},
        {value: 'deletePrice', label: 'Удалить'}
      ],
      operators: ['МТС', 'Ростелеком', 'Yota', 'Мегафон', 'Tele2', 'Билайн', 'Мотив', 'КТК-Телеком', 'Прочие операторы'],
      table: {
        data: [],
        headers: [
          {text: 'Оператор', value: 'operator', sortable: false},
          {text: 'Лимит', value: 'limit', sortable: false},
          {text: 'Цена', value: 'price', sortable: false},
          {text: 'Действия', value: 'action_menu', sortable: false, width: 200}
        ]
      },
      loading: true,
      popup: {
        price: false,
      },
      valuePopupPrice: {
        _id: null,
        operator: '',
        price: null,
        limit: null,
      },
      showAlert: false,
      AlertData: {
        visible: true,
        type: "success",
        title: '',
        text: '',
      }
    }
  },
  methods: {
    changePrice(item) {
      this.valuePopupPrice._id = item._id
      this.valuePopupPrice.operator = item.operator
      this.valuePopupPrice.limit = item.limit
      this.valuePopupPrice.price = item.price
      this.popup.price = true
    },

    deletePrice(item) {
      let _this = this
      _this.$http.post("/admin/rates/delete", {_id: item._id}, () => {
        _this.loading = false
        _this.popup.price = false
        _this.render()
        _this.showAlertModal("Удаление", "Удаление успешно выполнено!")
      })
    },

    async save() {
      this.loading = true
      let data = {
        operator: this.valuePopupPrice.operator,
        limit: this.valuePopupPrice.limit,
        price: this.valuePopupPrice.price
      }
      if (this.valuePopupPrice._id) {
        data._id = this.valuePopupPrice._id
      }

      await this.$http.post("/admin/rates/update", data, () => {
        this.closePopup()
        this.render()
        this.loading = false
        this.showAlertModal("Цена", "Успешно сохранено!")
      })
    },

    closePopup() {
      this.popup.price = false
      this.valuePopupPrice = {
        _id: null,
        operator: '',
        price: null,
        limit: null,
      }
    },
    render() {
      this.$http.post("/admin/rates/list", {}, result => {
        this.table.data = result
        this.loading = false
      })
    },
    showAlertModal(title, text) {
      this.AlertData.title = title
      this.AlertData.text = text
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
    },
  },
  mounted() {
    this.render()
  }
}
</script>

<style scoped>
.alert_modal {
  width: 300px;
  position: fixed;
  top: 100px;
  right: 10px;
  pointer-events: none;
  transform: translateX(310px)
}

.alert_modal.active {
  animation: alert 3s ease-in-out;
}

@keyframes alert {
  0% {
    transform: translateX(310px);
  }
  30% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(310px);
  }
}
</style>