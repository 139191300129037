<template>
	<div class="render">
		<div class="top">
			<h1>Сервисные шаблоны</h1>
			<CreateTemplate :get_tags="tags" :show_dialog="create_popup" @close="create_popup = false"/>
		</div>
		<div class="content_table">
			<Table
					:server="true"
					:hideselect="true"
					:loading="loading"
					:items="action_items"
					:headers="headers"
					:data="data"
					:perPage="perPage"
					:pageCount_server="1"
					:callback_refresh="renderTemplates"
					@update="updateTemplateEmit"
			/>
		</div>
		<UpdateTemplate :get_tags="tags" :show_dialog="update_popup" @close="update_popup = false"/>
	</div>
</template>

<script lang="js">
import Table from '../components/Table/DataTable.vue'
import CreateTemplate from '../components/Modal/Templates/CreateTemplate.vue'
import UpdateTemplate from '../components/Modal/Templates/UpdateTemplate.vue'

export default {
	name: 'TemplatesView',
	components: {
		Table,
		CreateTemplate,
		UpdateTemplate
	},
	mixins: [],
	props: [],
	data() {
		return {
			data: [],
			loading: true,
			create_popup: false,
			update_popup: false,
			tags: [],
			templates: [],
			headers: [
				{text: 'ID', value: '_id', width: 100},
				{text: 'Название', value: 'name'},
				{text: 'Текст', value: 'text'},
				{text: 'Обновлено', value: 'updated'},
				{text: 'Статус', value: 'statusAlpha'},
				{text: 'Действия', value: 'actions', width: 100}
			],
			action_items: [
				{title: 'Обновить', action: 'update'},
				{title: 'Удалить', action: 'delete'}
			],
			edit_tag: {
				id: 0,
				item: {}
			},
			perPage: 10,
		}
	},
	computed: {},
	watch: {},
	created() {
	},
	methods: {
		editTemplate(visible, template) {
			this.loading = true
			if (template) this.update_template = template
			this.update_popup = visible
			console.log(visible, template)
		},
		renderTemplates() {
			this.$http.post("/template/list", {}, data => {
				this.data = data
				this.loading = false
			})
			this.getTags()
		},
		getTags() {
			this.$http.post("/tag/list", {}, data => {
				this.tags = data
			})
		},
		updateTemplateEmit(item) {
			this.loading = true
			this.editTemplate(true, {id: item.id, item})
		}
	}
}
</script>
<style lang="sass" scoped>
.top
  margin-bottom: 30px
</style>