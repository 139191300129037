<template>
  <DefaultView titlePage="Пользователи">
    <div>
      <FilterCustom @updateFilter="onUpdateFilter" :visible="{phone:true,maska:'+7 ### ###-##-##'}"/>
      <BaseTable
          :loading="loading"
          :headers="users_table.headers"
          :data="users_table.filterData.slice((pagination_number - 1) * 10, pagination_number * 10)"
      >
        <template v-slot:status="{ item }">
          <SelectTable :value="item.status"
                       :key="item._id"
                       :options="optionsSelectStatus"
                       @update="val => updateUserStatus(val, item)"
          />
        </template>
        <template v-slot:action="{ item }">
          <v-list-item @click="addBalancePopup(item)">
            <v-list-item-title>
              Пополнить
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="reversalPopup(item)">
            <v-list-item-title>
              Сторнирование
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="informUserPopup(item._id)">
            <v-list-item-title>
              Юр.инфо
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="router(item)">
            <v-list-item-title>
              Подробная статистика
            </v-list-item-title>
          </v-list-item>
        </template>
      </BaseTable>
      <v-pagination :length="Math.ceil(users_table.filterData.length / 10)" v-model="pagination_number"
                    :color="'#60c880'"></v-pagination>

      <BaseModal
          :show-modal="popup.balance" @close="closePopup"
          :title="`Пополнение баланса пользователя: ${valuePopupBalance.phone}`"
          textBtn="Пополнить"
          @clickBtn="addBalance"
      >
        <WrapperInputs title-inputs="Сумма пополнения">
          <v-text-field
              v-model="valuePopupBalance.addBalance"
              placeholder="Сумма"
              type="number"
          />
          <v-text-field
              v-model="valuePopupBalance.comment"
              placeholder="Комментарий"
              type="text"
          />
        </WrapperInputs>
      </BaseModal>
      <BaseModal
          :show-modal="popup.reversal" @close="closePopup"
          :title="`Сторнирование баланса пользователя: ${valuePopupBalance.phone}`"
          textBtn="Списать"
          @clickBtn="reversal"
      >
        <WrapperInputs title-inputs="Сумма списания">
          <v-text-field
              v-model="valuePopupBalance.addBalance"
              placeholder="Сумма"
              type="number"
          />
          <v-text-field
              v-model="valuePopupBalance.comment"
              placeholder="Комментарий"
              type="text"
          />
        </WrapperInputs>
      </BaseModal>
      <BaseModal
          :show-modal="popup.inform"
          @close="closePopup"
          @clickBtn="closePopup"
          :title="`Информация пользователя:`"
          textBtn="Закрыть"
      >
        <div style="display: flex; flex-direction: column; align-items: flex-start">
          <div style="text-align: left">Наименование: {{ valuePopupInform.org_name}}</div>
          <div style="text-align: left">Должность: {{ valuePopupInform.manager_position_title}}</div>
          <div style="text-align: left">ФИО: {{ valuePopupInform.full_name}}</div>
          <div style="text-align: left">Банк: {{ valuePopupInform.bank_name}}</div>
          <div style="text-align: left">ИНН: {{ valuePopupInform.inn}}</div>
          <div style="text-align: left">БИК: {{ valuePopupInform.bik}}</div>
          <div style="text-align: left">ОГРН: {{ valuePopupInform.ogrn}}</div>
          <div style="text-align: left">КПП: {{ valuePopupInform.kpp}}</div>
          <div style="text-align: left">Юр. адрес: {{ valuePopupInform.legal_address}}</div>
          <div style="text-align: left">Почтоый адрес: {{ valuePopupInform.postal_address_for_sending_accounting_documents}}</div>
          <div style="text-align: left">Контактные данные: {{ valuePopupInform.email}}</div>
        </div>
      </BaseModal>
    </div>
  </DefaultView>
</template>
<script>
import FilterCustom from '../../components/FilterMy.vue'
import BaseTable from "@/components/UI/BaseTable.vue";
import BaseModal from "@/components/UI/BaseModal.vue";
import DefaultView from "@/components/ContainersView/DefaultView.vue";
import SelectTable from "@/components/UI/SelectTable.vue";
import WrapperInputs from "@/components/UI/WrapperInputs.vue";

export default {
  components: {
    BaseTable,
    FilterCustom,
    BaseModal,
    DefaultView,
    SelectTable,
    WrapperInputs,
  },
  data() {
    return {
      optionsSelectStatus: [{value: 'blocked', label: 'Заблокирован'},
        {value: 'activated', label: 'Активирован'},
        {value: 'unactivated', label: 'Не активирован'}],
      colorSelect: {
        activated: 'status_success',
        unactivated: 'status_wait',
        blocked: 'status_stop'
      },
      popup: {
        balance: false,
        inform: false,
        reversal: false
      },
      valuePopupBalance: {
        phone: '',
        _id: null,
        addBalance: null,
        comment: ''
      },
      valuePopupInform: {
        org_name: '',
        manager_position_title : '',
        full_name : '',
        bank_name : '',
        inn : '',
        bik : '',
        ogrn : '',
        kpp : '',
        checking_account : '',
        correspondent_account : '',
        legal_address : '',
        postal_address_for_sending_accounting_documents : '',
        email : '',
        phone : ''
      },
      filters: {},
      loading: true,
      users_table: {
        data: [],
        filterData: [],
        headers: [
          {text: 'Телефон', value: 'phone', sortable: false},
          {text: 'Имя', value: 'name', sortable: false},
          {text: 'Баланс', value: 'balance', sortable: false},
          {text: 'Статус', value: 'status', width: 350, sortable: false},
          {text: 'Действия', value: 'action_menu', sortable: false}
        ]
      },
      filters_data_users: false,
      pagination_number: 1
    }
  },
  mounted() {
    this.render()
    console.log("test_new_update")
  },

  methods: {
    router(item) {
      console.log(item)
      this.$router.push({path: '/user_statistic', query: {...item}})
    },
    updateUserStatus(val, item) {
      this.loading = true
      this.$http.post("/admin/user/update_status", {_id: item._id, status: val}, () => {
        this.render()
      })
    },

    informUserPopup(id) {
      this.$http.post('/admin/user/legal_info', {user_id: id}, (result) => {
        const {data} = result
        this.valuePopupInform.org_name = data.org_name
        this.valuePopupInform.manager_position_title = data.manager_position_title
        this.valuePopupInform.full_name = data.full_name
        this.valuePopupInform.bank_name = data.bank_name
        this.valuePopupInform.inn = data.inn
        this.valuePopupInform.bik = data.bik
        this.valuePopupInform.ogrn = data.ogrn
        this.valuePopupInform.kpp = data.kpp
        this.valuePopupInform.checking_account = data.checking_account
        this.valuePopupInform.correspondent_account = data.correspondent_account
        this.valuePopupInform.legal_address = data.legal_address
        this.valuePopupInform.postal_address_for_sending_accounting_documents = data.postal_address_for_sending_accounting_documents
        this.valuePopupInform.email = data.email
        this.valuePopupInform.phone = data.phone
      })
      this.popup.inform = true
    },

    addBalancePopup(item) {
      console.log(item)
      this.valuePopupBalance.phone = item.phone
      this.valuePopupBalance._id = item._id
      this.popup.balance = true
    },
    reversalPopup(item) {
      this.valuePopupBalance.phone = item.phone
      this.valuePopupBalance._id = item._id
      this.popup.reversal = true
    },
    addBalance() {
      this.loading = true
      this.$http.post("/admin/user/top-up", {
        _id: this.valuePopupBalance._id,
        sum: this.valuePopupBalance.addBalance,
        comment: this.valuePopupBalance.comment
      }, () => {
        this.popup.inform = false
        this.popup.balance = false
        this.render()
      })
    },
    reversal() {
      this.loading = true
      this.$http.post("/admin/user/top-up", {
        _id: this.valuePopupBalance._id,
        sum: this.valuePopupBalance.addBalance * -1,
        comment: this.valuePopupBalance.comment
      }, () => {
        this.popup.inform = false
        this.popup.balance = false
        this.render()
      })
    },
    closePopup() {
      this.popup.balance = false
      this.popup.reversal = false
      this.popup.inform = false
    },

    render() {
      this.$http.post("/admin/user/list", {}, data => {
        this.users_table.data = data
        this.loading = false
        this.filterDataUsers()
      })
    },

    onUpdateFilter(new_filter) {
      console.log(new_filter)
      this.filters_data_users = new_filter
      this.filterDataUsers()
    },

    filterDataUsers() {
      let newData = this.users_table.data
      if (this.filters_data_users.search) {
        newData = newData.filter(user => user.phone.includes(this.filters_data_users.search.replace(/[+\s-]/g, '')))
      }
      this.users_table.filterData = newData
    }
  }
}
</script>
<style scoped>
p {
  text-align: left;
}

.custom_tariff_save_message {
  display: flex;
}

.block_center {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.save_message {
  height: 41px;
  margin: 0 0 0 35px;
  color: #1caf1c;
  padding: 8px;
}

.useradmn_info {
  background: #FFFFFF;
  padding: 24px;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 6%), 0px 8px 24px rgb(0 0 0 / 10%);
  border-radius: 16px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.w100p {
  width: 100%;
}

.popup_text {
  text-align: center;
  margin-bottom: 25px;
}

.v_email_updated_massage {
  text-align: left;
  color: green;
}

.v_err_updated_massage {
  text-align: left;
  color: #b01111;
}

.mb26 {
  margin-bottom: 26px;
}

.top {
  margin-bottom: 24px;
}

.history_title {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 130%;
  color: #404247;
  text-align: left;
  margin-bottom: 8px;
}

.desc {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #55575C;
  text-align: left;
  margin-bottom: 16px;
}

.ok {
  margin-top: 30px;
  background: #E8F7F4;
  width: 100%;
  border-radius: 8px;
  padding: 16px 24px;
}

.cashup {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 120%;
  color: #2B2D33;
  background: #FEE600;
  border-radius: 8px;
  padding: 13px;
  margin-top: 40px;
  cursor: pointer;
}

.btn_custom_tariff {
  width: max-content;
  margin-left: auto;
}

.history {
  margin: 8px auto 0 auto;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #00809C;
  cursor: pointer;
  position: relative;
  width: max-content;
}

.history:after {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background: #00809C;
  position: absolute;
  bottom: -4px;
}

.save {
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  padding: 9px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-top: 16px;
  line-height: 110%;
  color: #2B2D33;
  max-width: max-content;
  cursor: pointer;
}

.top {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.inform {
  display: flex;
  background: #FFFFFF;
  padding: 24px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.06), 0 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  justify-content: space-between;
}

.left {

  width: 50%;
  height: max-content;
}

.content {
  display: flex;
}

.right {
  width: 50%;
  padding-left: 32px;
}

h1 {
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 130%;
  color: #404247;
}

h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  color: #404247;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 24px;
}

.label {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #404247;
  text-align: left;
  margin-bottom: 6px;
}

.balance {
  width: 40%;
}

.left .title {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #55575C;
  text-align: left;
  margin-bottom: 4px;
}

.left .num {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 130%;
  color: #404247;
  text-align: left;
}

.alert {
  background: #FEF3EC;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;

}

.left_text {
  display: flex;
  align-items: center;
}

.left_text .text {
  margin-left: 18px;
}

.mmm_data {
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  text-align: center;
  color: #2B2D33;
  padding: 7px 17px;
  cursor: pointer;
}

.vback {
  border: 1px solid #c3c4c6;
  padding: 10px;
  border-radius: 10px;
}

.vback:hover {
  cursor: pointer;
}

.ok .text {
  color: #008E7D;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
}
</style>