<template>
	<BaseModal
		:show-modal="showPopup"
		width="1200"
		@close="closePopup()"
		:overlay="false"
		@clickBtn="save()"
		:disabledBtn="!checkColumnPhone"
	>
		<div style="width: 100%; min-width: 650px; overflow-x: scroll; overflow-y: scroll; max-height: calc(100vh - 300px); height: 100%;">
			<div style="margin-bottom: 10px">Найдено: {{ valueTable.length }} штуки</div>
			<table class="table_custom">
				<thead>
				<tr style="border-bottom: 1px solid #f0f0f0;">
					<th v-for="column in countColumn" :key="column">
						<span v-if="column === 1"></span>
						<v-select
							v-else
							v-model ="valueColumn[column - 1]"
							:items="optionsSelect"
							:style="{width: '200px', height: '56px'}"
							item-text="label"
							item-value="value"
							hide-details
							@change="changeNameColumn()"
						></v-select>
					</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(row,idxRow) of firstTenRows" :key="idxRow">
					<td v-for="(value,idx) of row" :key="idx">
					<span v-if="idx === 0" @click="deleteRow(idxRow)">
<!--						<delete-outlined style="color: #ff4141"/>-->
						<setIcon name="delete"/>
					</span>
						<span v-else>{{ value }}</span>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</BaseModal>
</template>

<script>
import setIcon from "@/components/SetIcon.vue"
import BaseModal from "@/components/UI/BaseModal.vue"

export default {
	name: "CustomTableSaveCSV",
	props: {
		valueTable: {
			type: Array,
			required: true
		},
		showPopup: {
			type: Boolean,
            required: true,
			default: false
		}
	},
	components: {setIcon, BaseModal},
	computed: {
		firstTenRows() {
			return this.table
		},
		countColumn() {
			if (this.table.length) return this.table[0].length
			else return 0
		},
		checkColumnPhone() {
			return !!this.valueColumn.find(item => item === 'phone')
		},
		// valueColumn(){
		// 	return Array(this.countColumn)
		// }
	},
	data() {
		return {
			resData: [],
			table: [],
			valueColumn: [],
			optionsSelect: []
		}
	},
	methods: {
		save(){
			if (this.checkColumnPhone){
				this.$emit('saveTable', this.resData)
				this.closePopup()
			}
		},
		changeNameColumn() {
			let res = []
			this.table.forEach((data) => {
				let field = {}
				data.forEach((val, idx) => {
					if (this.valueColumn[idx] && this.valueColumn[idx] !== '-') {
						if (val) field[this.valueColumn[idx]] = val
					}
				})
				res.push(field)
			})
			console.log(res)
			this.resData = res
			// this.$emit('resData', res)
		},
		addColumnDelete() {
			if (this.table[0][0] !== 'delete_col') {
				this.table.forEach(item => {
					item.unshift('delete_col')
				})
			}
			this.changeNameColumn()
		},
		deleteRow(id) {
			console.log(id)
			this.table.splice(id, 1)
			this.changeNameColumn()
		},
		closePopup(){
			this.$emit('close')
		}
	},
	watch: {
		valueTable() {
			this.table = this.valueTable
			this.addColumnDelete()
			this.valueColumn = Array(this.countColumn)
		}
	},
	beforeMount(){
		this.$http.post("/tag/list", {}, data => {
			console.log(data)
			this.optionsSelect = data.map((item) => ({value: item.slag, label: item.name}))
			this.optionsSelect.unshift({value: 'phone', label: 'Телефон'})
		})
	},
	mounted() {
		// тут запрос на получение колонок
		this.table = this.valueTable
		this.addColumnDelete()
		this.valueColumn = Array(this.countColumn)
	}
}
</script>

<style scoped>
.table_custom {
    border-top: 1px solid #f0f0f0;
    overflow-y: scroll;
    height: 200px;
}

.table_custom > thead {
    border: 1px solid #f0f0f0;
}

.table_custom > thead tr {
    background: #FFFFFF;
    position: sticky;
    top: -2px;
    z-index: 1;
}

.table_custom tr {
    border-bottom: 1px solid #f0f0f0;
}


.table_custom tr > th,
.table_custom tr > td {
    padding: 12px;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
}
</style>