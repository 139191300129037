<template>
	<v-select
			class="select_table"
			style="max-width: 300px; margin-top: 10px;"
			v-model="modalValue"
			:items="options"
			item-text="label"
			item-value="value"
			dense
			hide-details
			variant="underlined"
			@change="$emit('update', modalValue)"
	>
		<template v-slot:selection="{item}">
			<div :class="colorSelect[item.value]">{{ item.label }}</div>
		</template>
	</v-select>
</template>

<script>
export default {
	name: "SelectTable",
	emit: ['update'],
	props: {
		value: {
			type: String,
			required: true
		},
		options: {

			default: []
		}
	},
	data() {
		return {
			modalValue: null,
			colorSelect: {
        activated: 'status_success',
				waiting: 'status_wait',
        unactivated: 'status_stop',
				off: 'status_stop',
			},
		}
	},
	mounted() {
		this.modalValue = this.value
	}
}
</script>