<template>
	<div class="add" @click="$emit('click')">
		<Icon :name="iconBtn" v-if="!!iconBtn"/>
		<span>{{ textBtn }}</span>
	</div>
</template>

<script>
import Icon from '@/components/SetIcon.vue'

export default {
	name: "BigButton",
	components: { Icon },
	emit: ['click'],
	props: {
		textBtn: {
			type: String,
			require: true
		},
		iconBtn: String,
	}
}
</script>

<style scoped>
.add {
    border: none;
    padding: 12px 20px 12px 22px;
    background: #FEE600 !important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #2B2D33;
    cursor: pointer;
    display: flex;
    /*margin-top: 24px;*/
    justify-content: center;
}

.add .icon {
    margin-right: 11px;
}

</style>