<template>
	<DefaultView :titlePage="`Отчет: ${idPage}`">
		<template v-slot:right-title>
			<BigButton text-btn="Скачать" @click="downloadReport" />
		</template>
		<BaseTable
				:loading="loading"
				:headers="table.headers"
				:data="table.data"
		>

		</BaseTable>


	</DefaultView>
</template>

<script>
import DefaultView from "@/components/ContainersView/DefaultView.vue";
import BaseTable from "@/components/UI/BaseTable.vue";
import BigButton from "@/components/UI/BigButton.vue";

export default {
	name: "OneReportView",
	components: {
		DefaultView,
		BaseTable,
		BigButton,
	},
	data() {
		return {
			table: {
				data: [],
				filterData: [],
				headers: [
					// {text: 'Телефон', value: 'phone'},
					{text: 'Название', value: 'name'},
					{text: 'Период', value: 'range_date'},
					{text: 'Отправлено', value: 'sent'},
					{text: 'Доставлено', value: 'delivered'},
					// {text: 'Статус', value: 'status', width: 350, sortable: false},
					// {text: 'Действия', value: 'action', sortable: false}
				]
			},
			loading: true,
		}
	},
	computed: {
		idPage() {
			return this.$route.params.id
		},
	},
	methods: {
		downloadReport(link) {
			// скачивает
			console.log(link)
		},
		render(){
			this.table.data = [
				{
					name: 'kak alpha',
					range_date: '12.12.12 - 01.01.23',
					sent: '123312343',
					delivered: '234435',
				},
				{
					name: 're re re',
					range_date: '12.12.12 - 01.01.23',
					sent: '3243',
					delivered: '54',
				}
			]

			this.loading = false
		}
	},
	mounted() {
		this.render()
	}
}
</script>

<style scoped>

</style>