<template>
	<div class="table">
		<v-data-table
			locale="ru-RU"
			checkbox-color="#FEE600"
			:loading="loading"
			:headers="headers"
			:items="data"
			:itemsPerPage="perPage"
			:server="true"
			hide-default-footer
			no-data-text="Нет данных"
			class="elevation-1"
			loading-text="Загружаем данные таблицы"
		>
			<template #progress>
				<v-progress-linear
					indeterminate
					color="green"
				></v-progress-linear>
			</template>

			<template v-slot:[`item`]="{item}">
				<tr>
					<td style="text-align: left;" v-for="(column, key) of columnsTable" :key="key">
						<slot :name="column" v-bind="{item}">
							<div v-if="column === 'phone'" class="table_text">{{ phoneCustom(item.phone) }}</div>
							<div v-else-if="column ==='balance'" class="table_text">
								{{ (+item.balance).toLocaleString('ru') + ' руб.' }}
							</div>
							<div v-else-if="column ==='price'" class="table_text">
								{{ (+item.price).toLocaleString('ru') + ' руб.' }}
							</div>
							<div v-else class="table_text">{{ item[column] }}</div>
						</slot>

						<div v-if="column === 'action_menu'">
							<v-menu bottom>
								<template v-slot:activator="{ on, attrs }">
									<div class="props" v-bind="attrs" v-on="on">
										<Icon name="props"/>
									</div>
								</template>
								<v-list>
									<slot :name="'action'" v-bind="{item}"></slot>
								</v-list>
							</v-menu>
						</div>
					</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import formattingPhoneMixin from "@/mixins/formattingPhoneMixin";
import Icon from '../SetIcon.vue'

export default {
	name: "BaseTable",
	components: {Icon},
	mixins: [formattingPhoneMixin],
	computed: {
		columnsTable() {
			return this.headers.map(item => item.value)
		}
	},
	props: {
		loading: {
			type: Boolean,
			default: true
		},
		perPage: {
			type: Number,
			default: 10
		},
		headers: {
			type: Array,
			required: true
		},
		data: {
			type: Array,
			required: true
		},
		typeAction: {
			type: String,
			default: 'action_menu',
			validator: (value) => [
				'action_menu',
				'action_one'
			].includes(value)
		}
	}
}
</script>

<style scoped>
.table {
    background: #fff;
    border-radius: 8px;
    padding: 20px 16px 32px 16px;
}

.table_text {
    max-width: 300px;
    width: 100%;
}
</style>