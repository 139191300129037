import Vue from 'vue'
export const mutations = {
    loginUser(state, data) {
        localStorage.token=data.token
        Vue.set(state, 'user', data.user_data)
        Vue.set(state, 'token', data.token)
        Vue.set(state, 'login', true)
    },
    logoutUser(state) {
        localStorage.removeItem("token")
        Vue.set(state, 'user', false)
        Vue.set(state, 'token', false)
        Vue.set(state, 'login', false)
    },
    setEmail(state,data){
        state.user.email  =  data.email
      Vue.set(state, 'user', state.user)
    },
    setNotifications(state,data){
        state.user.low_balance_notification  =  data.low_balance_notification
        state.user.call_end_notification  =  data.call_end_notification
      Vue.set(state, 'user', state.user)
    },
    setStatus(state,data){
        state.user.user_status  =  data
      Vue.set(state, 'user', state.user)
    },
    setPayments(state,data){
        state.user.payments  =  data
        Vue.set(state, 'user', state.user)
    },
    setPolicy(state,data){
        Vue.set(state, 'check_policy', data)
    },
    setBalance(state,data){
        state.user.balance  =  data
        Vue.set(state, 'user', state.user)
    },
    setMinutes(state,data){
        state.user.minutes  =  data
        Vue.set(state, 'user', state.user)
    }
}