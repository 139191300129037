<template>
    <v-alert v-if="obj.visible"
      :type="obj.type"
      :title="obj.title"
      variant="tonal"
    >{{obj.text}}</v-alert>
</template>
<script>
export default {
  props: ['obj']
}
</script>