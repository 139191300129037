<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="530" >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="add">
          Изменить пароль
        </v-btn>
      </template>

      <div @click="dialog = false" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
        <div class="modal_title">
          Изменение пароля
        </div>

        <div class="items_wrapper">
          <form class="item_row">
            <v-text-field
              label="Старый пароль"
              v-model="pass"
              :append-icon="show_old ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show_old ? 'text' : 'password'"
              name="input-10-1"
              hint="Минимум 8 символов"
              counter
              @click:append="show_old = !show_old">
            </v-text-field>
            <v-text-field
              label="Новый пароль"
              v-model="new_pass"
              :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show_pass ? 'text' : 'password'"
              name="input-10-1"
              hint="Минимум 8 символов"
              counter
              @click:append="show_pass = !show_pass">
            </v-text-field>
            <v-text-field
              label="Повтор нового пароля"
              v-model="new_repeat"
              :append-icon="show_new ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show_new ? 'text' : 'password'"
              name="input-10-1"
              hint="Минимум 8 символов"
              counter
              @click:append="show_new = !show_new">
            </v-text-field>
            <v-btn @click="save">Сохранить</v-btn>
          </form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../../SetIcon.vue'
export default {
  components: {
    Icon
  },
  data(){
    return {
      dialog: false,
      show_old: false,
      show_pass: false,
      show_new: false,
      pass: '',
      new_pass: '',
      new_repeat: '',
      rules: {
				required: true,
				min: 8
			},
    }
  },
  methods: {
    save(){
      if(this.new_pass === this.new_repeat){
        this.$http.post("/user/change_pass", {old: this.pass, new: this.new_pass}, () => {
          this.dialog = false
        })
      }
      else{
        alert("пароли не совпадают")
      }
    }
  }
}
</script>
<style scoped>
.items_wrapper{
  display: flex;
  flex-direction: column;
}
.wrapper{
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.datar{
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  color: #2B2D33;
}
.datar_btn{
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #00809C;
  cursor:pointer;
}
.item_row{
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  margin-top: 12px;
  justify-content: flex-start;
}
.modal_title{
  padding: 0!important;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-top: 19px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2B2D33;
}
</style>