<script>
import BigButton from "@/components/UI/BigButton.vue";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
export default {
  name: "UserStatistic",
  components: {BigButton},
  data() {
    return {
      smsAlphas: [],
      emailsAlphas: [],
      smsStat: [],
      emailsStat: [],
      dates: [`${dayjs().format("YYYY-MM-DD")}`, `${dayjs().format("YYYY-MM-DD")}`],
      menu: false,
      inputValue: '',
      users_table: {
        data: [],
        filterData: [],
        headers: [
          {text: 'id', value: 'id', sortable: false},
          {text: 'Название', value: 'name', sortable: false},
          {text: 'Дата создания', value: 'date_time', sortable: false},
          {text: 'Кол-во смс/email', value: 'sms', sortable: false},
          {text: 'Кол-во кликов', value: 'click', sortable: false},
          {text: 'Доставлено', value: 'delivery', sortable: false},
          {text: 'Не доставлено', value: 'nodelivery', sortable: false},
          {text: 'Процент доставки', value: 'perc', sortable: false},
          {text: 'Цена', value: 'price', sortable: false},
          {text: 'Выгрузить смс/email', value: 'download_sms_and_emails', sortable: false},
          {text: 'Выгрузить клики', value: 'download_clicks', sortable: false},
        ]
      },
    }
  },
  methods: {
    listUserData(userId) {
      this.$http.post("/admin/reports/user_all_stat", {user_id: userId}, (data) => {
        this.smsAlphas = data.smsAlphas
        this.emailsAlphas = data.emailsAlphas
        this.smsStat = data.smsStat
        this.emailsStat = data.emailsStat
      })
    },
    setDate(preset) {
      switch (preset) {
        case "all_time":
          this.dates = ["2020-01-01", `${dayjs().format("YYYY-MM-DD")}`]
          break;
        case "today":
          this.dates = [`${dayjs().format("YYYY-MM-DD")}`, `${dayjs().format("YYYY-MM-DD")}`]
          break;
        case "tomorrow":
          this.dates = [`${dayjs().subtract(1, 'day').format("YYYY-MM-DD")}`, `${dayjs().subtract(1, 'day').format("YYYY-MM-DD")}`]
          break;
        case "3":
          this.dates = [`${dayjs().subtract(2, 'day').format("YYYY-MM-DD")}`, `${dayjs().format("YYYY-MM-DD")}`]
          break;
        case "7":
          this.dates = [`${dayjs().subtract(6, 'day').format("YYYY-MM-DD")}`, `${dayjs().format("YYYY-MM-DD")}`]
          break;
        case "30":
          this.dates = [`${dayjs().subtract(29, 'day').format("YYYY-MM-DD")}`, `${dayjs().format("YYYY-MM-DD")}`]
          break;
        case "month":
          this.dates = [`${dayjs().startOf('month').format("YYYY-MM-DD")}`, `${dayjs().format("YYYY-MM-DD")}`]
          break;
      }
    },
    downloadUserBalanceHistory() {
      this.$http.post("/admin/reports/user_balance_history", {user_id: this.$route.query._id }, (data) => {
        const worksheet = XLSX.utils.json_to_sheet(
            Object.values(data).map((item) => ({
              'Тип транзакции': item.type,
              'Сумма': item.price,
              'Дата': item.date,
            }))
        );

        const workbook = XLSX.utils.book_new();
        const sheetName = `Баланс - ${this.$route.query.phone}`

        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        XLSX.writeFile(workbook, sheetName + '.xlsx');
      });

    },
    getMailingsData() {
      const date2 = {
        _value: this.dates
      }
      this.$http.post("/reports/mailings", {
        date: date2,
        name: this.inputValue,
        userid: this.$route.query._id
      }, (data) => {
        this.users_table.data = data
      })
    },
    download(record, column) {
      let url = "/reports/getclick"
      if (column === 'download_sms_and_emails') {
        url = "/reports/getsms"
      }
      const date2 = {
        _value: this.dates
      }
      this.$http.post(url, {date: date2, sender: record.id}, (data) => {
        const rows = data
        let csvContent
        let filename = 'Выгрузка '
        if (column === 'download_sms_and_emails') {
          filename += 'смс '
          csvContent = '\uFEFFID; Рассылка; Телефон; Текст; Цена; Статус; Оператор; Альфа; Дата \n'
          csvContent += rows.map(e => e.id + ';' + e.sender + ';' + e.phone + ';' + e.text + ';' + `${e.price}` + ';' + e.delivery + ';' + e.operator + ';' + e.alpha + ';' + e.date_create).join("\n")
        } else {
          filename += 'кликов '
          csvContent = '\uFEFFID; Рассылка; Телефон;  Дата; Редирект; \n'
          csvContent += rows.map(e => e.id + ';' + e.sender + ';' + e.phone + ';' + e.date_create + ';' + e.redirect_data).join("\n")
        }
        const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});

        const url = window.URL.createObjectURL(blob)

        const a = document.createElement('a')

        a.setAttribute('href', url)

        a.setAttribute('download', filename + dayjs(new Date()).format('DD-MM-YYYY') + '.csv');

        a.click()
      })
    }
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
  },
  mounted() {
    this.listUserData(this.$route.query._id)
    this.getMailingsData()
  }
}
</script>

<template>
  <div class="container">
    <h1 class="phone">Страница Аккаунта {{ this.$route.query.phone }}</h1>
    <div class="row_statistic">
      <div class="stat__card all">
        <h3>Данные</h3>
        <div>id: <code>{{ this.$route.query._id }}</code></div>
        <div>Баланс: <code>{{ this.$route.query.balance }} рублей</code></div>
        <div>Номер телефона: <code>{{ this.$route.query.phone }}</code></div>
        <div>Статус: <code>{{ this.$route.query.status }}</code></div>
      </div>
      <div class="stat__card sms">
        <h3>Данные по смс</h3>
        <div>Отправлено: <code>{{ smsStat[0].sms_count }}</code></div>
        <div>Доставлено: <code>{{ smsStat[0].delivered }}</code></div>
        <div>Сумма отправок: <code>{{ smsStat[0].sum }} рублей</code></div>
      </div>
      <div class="stat__card email">
        <h3>Данные по email</h3>
        <div>Отправлено: <code>{{ emailsStat[0].emails_count }}</code></div>
        <div>Доставлено: <code>{{ emailsStat[0].delivered }}</code></div>
        <div>Прочитано: <code>{{ emailsStat[0].read_count }}</code></div>
        <div>Сумма отправок: <code>{{ emailsStat[0].sum }} рублей</code></div>
      </div>
      <div class="stat__card">
        <h3>Крутые кнопки</h3>
        <BigButton @click="downloadUserBalanceHistory" text-btn="Скачать историю баблишка"/>
      </div>
    </div>
    <div class="information">
      <div class="alpha__container">
        <h1>Альфы пользователя</h1>
        <div v-for="alpha in smsAlphas" :key="alpha._id" class="alpha__card sms">
          <div>Имя: <code>{{ alpha.name }}</code></div>
          <div>Статус: <code>{{ alpha.status }}</code></div>
          <div>id: <code>{{ alpha._id }}</code></div>
        </div>
        <div v-for="alphaEmail in emailsAlphas" :key="alphaEmail._id" class="alpha__card email">
          <h4>{{ alphaEmail.email }}</h4>
          <div>id: <code>{{ alphaEmail._id }}</code></div>
          <div>Имя: <code>{{ alphaEmail.name }}</code></div>
          <div>Статус: <code>{{ alphaEmail.status }}</code></div>
          <div>Была удалена: <code>{{ alphaEmail.blocked }}</code></div>
          <div>dkim_id: <code>{{ alphaEmail.dkim_id }}</code></div>
          <div>в черном списке: <code>{{ alphaEmail.blacklist }}</code></div>
        </div>
      </div>
      <div class="mailings">
        <h1>Рассылки</h1>
        <div class="pick--dates">
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateRangeText"
                  label="Picker in menu"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="dates"
                first-day-of-week="1"
                range
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(dates)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-text-field v-model="inputValue" type="text" class="input--filter" placeholder="Название" height="44"/>
          <v-menu offset-y :rounded="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                Пресеты
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <BigButton icon-btn="" text-btn="За все время" @click="setDate('all_time')"/>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <BigButton icon-btn="" text-btn="Cегодня" @click="setDate('today')"/>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <BigButton icon-btn="" text-btn="Вчера" @click="setDate('tomorrow')"/>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <BigButton icon-btn="" text-btn="Последние 3 дня" @click="setDate('3')"/>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <BigButton icon-btn="" text-btn="Последние 7 дней" @click="setDate('7')"/>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <BigButton icon-btn="" text-btn="Последние 30 дней" @click="setDate('30')"/>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <BigButton icon-btn="" text-btn="Этот месяц" @click="setDate('month')"/>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn @click="getMailingsData()" color="yellow">Фильтровать</v-btn>
        </div>
        <div class="card__container">
          <v-data-table
              :headers="users_table.headers"
              :items="users_table.data"
              :items-per-page="10">
            <template v-slot:item="{ item }">
              <tr>
                <td v-for="(column, index) in users_table.headers" :key="index">
                  <template v-if="column.value === 'download_sms_and_emails'">
                    <v-btn @click="download(item, 'download_sms_and_emails')">скачать</v-btn>
                  </template>
                  <template v-if="column.value === 'download_clicks'">
                    <v-btn @click="download(item, 'download_clicks')">скачать</v-btn>
                  </template>
                  <template v-else>
                    {{ item[column.value] }}
                  </template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.information {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.mailings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.pick--dates {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.row_statistic {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: fit-content;
  height: fit-content;
  gap: 20px;
}

.stat__card {
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 20px;
  background-color: #60c880;
  border-radius: 20px;
  font-size: 18px;
  font-weight: bold;
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.2);

  code {
    color: #fff;
    background-color: #285436;
  }
}

.alpha__container {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.alpha__card {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.2);

  &.sms {
    flex-direction: column;
    background-color: #fff6a6;
  }

  &.email {
    flex-direction: column;
    background-color: #d9ebff;
  }
}
</style>