<template>
    <div class="render">
      <h1>Справка</h1>
      <p>Мы рекомендуем выполнять действия с той последовательностью как мы описываем ниже</p>
      <h2>1. Имена отправителя</h2>
      <ol>
        <li>Перейдите в раздел Рассылки -> Имена отправителей</li>
        <li>Нажмите Добавить имя</li>
        <li>Заполните обязательные поля и прикрепите необходимые документы</li>
        <li>Ожидайте одобрения имени операторами</li>
      </ol>
      <h2>Тэги</h2>
      <p>Тэги - это дополнительные поля к номеру телефона в базе данных. Если вам это не нужно - пропустите этот этап</p>
      <h2>Базы</h2>
      <p>Загрузите базу(ы) клиентов</p>
      <h2>Шаблоны</h2>
      <p>Данный раздел необходим только если вам нужны сервисные рассылки</p>
      <h2>Рассылки</h2>
      <p></p>
    </div>
</template>

<script lang="js">
export default {
  name: 'ReferenceView',

  components: {

  },

  mixins: [],

  props: {},

  data () {
    return {}
  },

  computed: {},

  watch: {},

  created () {},

  methods: {}
}
</script>
<style lang="sass" scoped>

</style>