export const getters = {
    isLogin: state => {
        return state.login
    },
    getRole: state => {
        if (state.user) return state.user.role
    },
    getName: state => {
      if (state.user) return state.user.name
      else return false
    },
    getPhone: state => {
        if (state.user) {
            let login = state.user.phone
            login = '+'+login.substr(0, 1)+'-'+login.substr(1, 3) + '-' + login.substr(4, 3) + '-' + login.substr(7,2)+ '-' + login.substr(9,2)
            return login
        }
        else return false
    },
    getEmail: state => {
      if (state.user) return state.user.email
      else return false
    },
    getUserFields: state => {
      if (state.user) return state.user
      else return false
    },
    getBalance: state => {
      let bal = 0
      if (state.user && state.user.balance) bal = state.user.balance.toString()

      //  bal = (bal).toLocaleString('ru')
      if(bal.length > 6) return bal[0] + ' ' + bal[1] + bal[2] + bal[3]+ ' ' + bal[4] + bal[5] + bal[6]
      else if(bal.length > 5) return bal[0] + bal[1] + bal[2] + ' ' + bal[3] + bal[4] + bal[5]
      else if(bal.length > 4) return bal[0] + bal[1] + ' ' + bal[2] + bal[3] + bal[4]
      else if(bal.length > 3) return bal[0] + ' ' + bal[1] + bal[2] + bal[3]
      else return bal
    },
    getToken: state => {
      return state.token
    },
    getPolicy: state => {
      return state.check_policy
    },
    getOrg: state => {
      if(state.org.length) return state.org
      else return false
    }
}