<template> 
    <button :class="color" @click="callback">{{text}}</button>
</template>
<script>
export default {
    props: ['text', 'color','callback']
}

</script>
<style scoped>
button{
    padding: 13px 24px 15px;
    width: 100%;
    height: 48px;
    background: #fff;
    border-radius: 8px;
    margin: 16px auto;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 120%;
    align-items: center;
    text-align: center;
    color: #2B2D33;
    border: 1px solid #60c880;
    display: block;
    transition: .5s;
}
button:focus-visible{
    outline: none;
    border: 2px solid #00A5BC;
}
button:hover{
    cursor: pointer;
    border-color: #FED500;
    transition: .5s
}
.yellow{
    background: #FEE600;
    transition: .5s;
}
.yellow:hover{
    cursor: pointer;
    background: #FED500;
    transition: .5s
}
.green{
    background: #60c880;
    transition: .5s;
}
</style>