<template>
	<div class="render">
		<div class="top">
			<h1>{{ titlePage }}</h1>
			<slot name="right-title"></slot>
		</div>

		<div class="content_table">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "DefaultView",
	props: {
		titlePage: String,
	}
}
</script>

<style scoped>
.top {
	margin-bottom: 30px
}

</style>