<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="530" >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="add">
          Добавить
        </v-btn>
      </template>

      <div @click="dialog = false" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
        <div class="modal_title">
          Добавить реквизиты
        </div>

        <div class="items_wrapper">
          <div class="item_row">
            <v-text-field 
              v-model="inn"
              variant="plain"
              required
              clearable
              label="ИНН:"
              hint="Введите ИНН и нажмите Enter"
              counter 
              @keyup.enter="getCompanyInfo">
            </v-text-field>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../../SetIcon.vue'
export default {
  components: {
    Icon
  },
  props: ['data_org', 'cb'],
  data(){
    return {
      dialog: false,
      inn: ''
    }
  },
  methods: {
    getCompanyInfo(){
      const axios = require('axios')
      try{
        axios.request({
          method: 'get',
          maxBodyLength: Infinity,
          url: `https://api-fns.ru/api/egr?req=${this.inn}&key=aa31c4dec958ae58ae623bab118385b569a45d60`,
          headers: { }
        })
        .then(res => {
          console.log(res.data)
          this.cb(this.inn, res.data)
          this.dialog = false
        })
        .catch(e => {
          console.error(27, e)
        })
      }
      catch(e){
        console.error(61, e)
      }
    },
    save(){

    }
  }
}
</script>
<style scoped>
.items_wrapper{
  display: flex;
  flex-direction: column;
}
.wrapper{
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.datar{
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  color: #2B2D33;
}
.datar_btn{
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #00809C;
  cursor:pointer;
}
.item_row{
  display: flex;
  margin-bottom: 12px;
  margin-top: 12px;
  justify-content: flex-start;
}
.modal_title{
  padding: 0!important;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-top: 19px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2B2D33;
}
</style>