<template>
	<div class="text-center">
		<v-dialog v-model="dialog" width="530">
			<div @click="dialogclose" class="close">
				<Icon name="close"/>
			</div>
			<v-card class="wrapper">
				<div class="modal_title">
					<!--          Данные о компании-->
					Идентификация пользователя
				</div>
				<div class="desc">
					<span v-if="value === this.radios[0]">Для прохождения процедуры идентификации необходимо выбрать пользователя, загрузить необходимые
					документы и дождаться проверки</span>
					<span v-if="value === this.radios[1]">Для прохождения процедуры идентификации необходимо выбрать пользователя, загрузить необходимые документы и дождаться проверки и формирования договора-лицензии.</span>
				</div>
				<div class="items_wrapper">
					<div class="item_row">
						<div class="type_name">Пользователь</div>
						<v-radio-group row v-model="value">
							<v-radio
								active-class="thunder"
								color="#FEE600"
								light
								mandatory
								v-for="n in radios"
								:key="n"
								:label="n"
								:value="n"
							></v-radio>
						</v-radio-group>
					</div>

					<div v-if="value == this.radios[0]" class="item_tab">
						<div class="item_row">
							<div class="aftertext">
								Допустимо использовать фотографию или скан. Все данные должны быть отчётливо видны.
							</div>
						</div>
						<div class="item_row">
							<div class="titler">Скан паспорта</div>
							<div class="label">Основной разворот</div>
							<FileLoad types=".pdf, .jpg, .png" url="/cabinet/load" formats=".pdf, .doc, .jpg, .png" :isload="Boolean(pasportOneFile)"
									:callback_file="pasportOneLoadFile" :linkFile="pasportOneFile" :valid="validRadiosForm.individual"/>
<!--							<div class="aftertext">-->
<!--								Допустимо использовать фотографию или скан. Все данные должны быть отчётливо видны.-->
<!--							</div>-->
							<div class="label">Страница с актуальным адресом регистрации по месту жительства</div>
							<FileLoad types=".pdf, .jpg, .png" url="/cabinet/load" formats=".pdf, .doc, .jpg, .png" :isload="Boolean(pasportTwoFile)"
									:callback_file="pasportTwoLoadFile" :linkFile="pasportTwoFile" :valid="validRadiosForm.individual"/>
						</div>

<!--						<div class="item_row">-->
<!--							<div class="titler">Скан паспорта (страница с пропиской)</div>-->
<!--							<FileLoad types="doc" url="/cabinet/load" formats=".pdf, .doc, .jpg, .png" :isload="false"-->
<!--									:callback_file="pasportTwoLoadFile"/>-->
<!--&lt;!&ndash;							<div class="aftertext">&ndash;&gt;-->
<!--&lt;!&ndash;								Допустимо использовать фотографию или скан. Все данные должны быть отчётливо видны.&ndash;&gt;-->
<!--&lt;!&ndash;							</div>&ndash;&gt;-->
<!--						</div>-->
						<div class="item_row">
							<div class="titler">Ваше фото с паспортом в руках</div>
							<PhotoLoad types="jpg, png" url="/cabinet/loadPhoto" formats=".jpg, .png" :isload="Boolean(passportAndPersonPhoto)"
									:callback_file="passportAndPersonPhotoLoad" :photoLink="passportAndPersonPhoto" :valid="validRadiosForm.individual"/>
							<div class="aftertext">
								Сделайте селфи с паспортом в руках. Держите раскрытый основной
разворот паспорта рядом с лицом, должно быть четко видно: вас, фото в паспорте, ФИО и другие важные данные
							</div>
						</div>
					</div>

					<div v-if="value == this.radios[1]" class="item_tab">
						<div class="item_row">
							<div class="label">Полное официальное наименование</div>
							<v-text-field v-model="CompanyName" placeholder="ООО ”Тестовая компания”"></v-text-field>
							<div v-if="validRadiosForm.entityOOO && !CompanyName || validRadiosForm.entityIP && !CompanyName" class="valid_value">
								'Обязательно к заполнению!'
							</div>
						</div>

						<div class="item_row" v-if="valueType === this.radiosType[0]">
							<div class="label">ОГРН</div>
							<v-text-field v-model="CompanyInn" placeholder="000000000000000" type="number"></v-text-field>
							<div v-if="validRadiosForm.entityOOO && !CompanyInn || validRadiosForm.entityIP && !CompanyInn" class="valid_value">
								Обязательно к заполнению!
							</div>
							<div v-if="!validCountNumberOOO && validRadiosForm.entityOOO && CompanyInn" class="valid_value">
								Нужное количество символов 13
							</div>

						</div>

						<div class="item_row" v-if="valueType === this.radiosType[1]">
							<div class="label">ОГРН</div>
							<v-text-field v-model="CompanyInn" placeholder="0000000000000" type="number"></v-text-field>
							<div v-if="validRadiosForm.entityOOO && !CompanyInn || validRadiosForm.entityIP && !CompanyInn" class="valid_value">
								Обязательно к заполнению!
							</div>
							<div v-if="!validCountNumberIP && validRadiosForm.entityIP && CompanyInn" class="valid_value">
								Нужное количество символов 15
							</div>
						</div>



						<div class="item_row">
<!--							<div class="type_name">Тип чего-то там</div>-->
							<div class="titler">Форма организации</div>
							<v-radio-group row v-model="valueType">
								<v-radio
									active-class="thunder"
									color="#FEE600"
									light
									mandatory
									v-for="n in radiosType"
									:key="n"
									:label="n"
									:value="n"
								></v-radio>
							</v-radio-group>
						</div>
						<div class="item_row">
							<div class="aftertext">
								Допустимо использовать фотографию или скан. Все данные должны быть отчётливо видны.
							</div>
						</div>
						<div class="item_row">
							<div v-if="valueType === radiosType[0]" class="titler">Свидетельство о государственной регистрации в качестве индивидуального предпринимателя</div>
							<div v-if="valueType === radiosType[1]" class="titler">Свидетельство о государственной регистрации</div>
							<FileLoad types=".pdf, .jpg, .png" url="/cabinet/load" formats=".pdf, .doc, .jpg, .png" :isload="Boolean(InnFile)"
									:callback_file="InnLoadFile" :linkFile="InnFile" :valid="validRadiosForm.entityOOO || validRadiosForm.entityIP" />
<!--							<div class="aftertext">-->
<!--								Допустимо использовать фотографию или скан. Все данные должны быть отчётливо видны.-->
<!--							</div>-->
						</div>

						<div v-if="valueType === radiosType[0]" class="item_row">
							<div class="titler">Решение об избрании руководителя организации (генеральный директор, президент и т.п.)</div>
							<FileLoad types=".pdf, .jpg, .png" url="/cabinet/load" formats=".pdf, .doc, .jpg, .png" :isload="Boolean(SolutionCompanyFile)"
									:callback_file="SolutionCompanyLoadFile" :linkFile="SolutionCompanyFile" :valid="validRadiosForm.entityOOO"/>
							<div class="titler">Приказ о назначении руководителя организации</div>
							<FileLoad types=".pdf, .jpg, .png" url="/cabinet/load" formats=".pdf, .doc, .jpg, .png" :isload="Boolean(OrderCompanyFile)"
									:callback_file="OrderCompanyLoadFile" :linkFile="OrderCompanyFile" :valid="validRadiosForm.entityOOO"/>
							<div class="titler">Карточка организации</div>
							<FileLoad types="doc, .pdf, .jpg, .png" url="/cabinet/load" formats="doc, .pdf, .jpg, .png" :isload="Boolean(CompanyFile)"
									:callback_file="CompanyLoadFile" :linkFile="CompanyFile" :valid="validRadiosForm.entityOOO"/>
<!--							<div class="aftertext">-->
<!--								Допустимо использовать фотографию или скан. Все данные должны быть отчётливо видны.-->
<!--							</div>-->
						</div>
<!--						<div v-if="valueType === radiosType[1]" class="item_row">-->
<!--							<div class="item_row">-->
<!--								<div class="titler">Скан паспорта (основная страница)</div>-->
<!--								<FileLoad types="doc" url="/cabinet/load" formats=".pdf, .doc" :isload="false"-->
<!--										:callback_file="pasportOneLoadFile"/>-->
<!--							</div>-->

<!--							<div class="item_row">-->
<!--								<div class="titler">Скан паспорта (страница с пропиской)</div>-->
<!--								<FileLoad types="doc" url="/cabinet/load" formats=".pdf, .doc" :isload="false"-->
<!--										:callback_file="pasportTwoLoadFile"/>-->
<!--							</div>-->
<!--						</div>-->
						<div v-if="valueType === radiosType[1]" class="item_row">
							<div class="titler">Скан паспорта</div>
							<div class="label">Основной разворот</div>
							<FileLoad types=".pdf, .jpg, .png" url="/cabinet/load" formats=".pdf, .doc, .jpg, .png" :isload="Boolean(pasportOneFile)"
									:callback_file="pasportOneLoadFile" :linkFile="pasportOneFile" :valid="validRadiosForm.entityIP"/>
<!--							<div class="aftertext">-->
<!--								Допустимо использовать фотографию или скан. Все данные должны быть отчётливо видны.-->
<!--							</div>-->
							<div class="label">Страница с актуальным адресом регистрации по месту
жительства</div>
							<FileLoad types=".pdf, .jpg, .png" url="/cabinet/load" formats=".pdf, .doc, .jpg, .png" :isload="Boolean(pasportTwoFile)"
									:callback_file="pasportTwoLoadFile" :linkFile="pasportTwoFile" :valid="validRadiosForm.entityIP"/>
						</div>


<!--						<div class="item_row">-->
<!--							<div class="titler">Карточка компании</div>-->
<!--							<FileDrop types="doc" url="/cabinet/load" formats=".pdf, .doc" :isload="false"-->
<!--									:callback_file="CompanyLoadFile"/>-->
<!--							<div class="aftertext">-->
<!--								Допустимо использовать фотографию или скан. Все данные должны быть отчётливо видны.-->
<!--							</div>-->
<!--						</div>-->

					</div>

<!--					<div class="item_row">-->
<!--&lt;!&ndash;						<div class="type_name">Тип чего-то там</div>&ndash;&gt;-->
<!--						<div class="titler">Вы являетесь официальным сотрудником компании?</div>-->
<!--						<v-radio-group row v-model="valueWorker">-->
<!--							<v-radio-->
<!--								active-class="thunder"-->
<!--								color="#FEE600"-->
<!--								light-->
<!--								mandatory-->
<!--								v-for="n in radiosWorker"-->
<!--								:key="n"-->
<!--								:label="n"-->
<!--								:value="n"-->
<!--							></v-radio>-->
<!--						</v-radio-group>-->
<!--						<template v-if="valueWorker === radiosWorker[1]">-->
<!--							<div class="titler">Доверенность на представление интересов организации (в свободной форме)-->
<!--							</div>-->
<!--							<FileDrop types="doc" url="/cabinet/load" formats=".pdf, .doc" :isload="false" :callback_file="CompanyLoadFile"/>-->
<!--							<div class="aftertext">-->
<!--								Допустимо использовать фотографию или скан. Все данные должны быть отчётливо видны.-->
<!--							</div>-->
<!--						</template>-->
<!--					</div>-->

<!--					<div class="item_row">-->
<!--						<div class="label">ФИО номера человека чей номер используется в аккаунте</div>-->
<!--						<v-text-field v-model="EmailCompany" placeholder="ФИО"></v-text-field>-->
<!--					</div>-->
					<div class="item_row">
						<div class="label mb-2">{{ value === radios[0] ? 'Email' : 'Авторизованный Email организации'}}</div>
						<v-text-field v-model="Email" placeholder="Укажите email"></v-text-field>
						<div v-if="validRadiosForm.individual && !Email
							||validRadiosForm.entityOOO && !Email
							||validRadiosForm.entityIP && !Email"
							class="valid_value">
							'Обязательно к заполнению!'
						</div>
					</div>
					<div class="check_policy">
						<v-checkbox v-model="check_doc"></v-checkbox>
						<div class="check_policy_text">
							Я даю <a target="_blank" href="https://crmcalls.ru/wp-content/themes/crmcalls/assets/assets/pdf/soglasie.pdf">согласие</a> на получение рекламно-информационных материалов от CRMCalls
						</div>
					</div>
<!--					<div class="check_policy">-->
<!--&lt;!&ndash;						<v-checkbox v-model="check_doc_two"></v-checkbox>&ndash;&gt;-->
<!--						<div class="check_policy_text">-->
<!--							За все действия совершенные в личном кабинете ответственность несет Лицензиат согласно договору-лицензии-->
<!--&lt;!&ndash;							<a target="_blank" href="https://crmcalls.ru/assets/oferta.pdf">договору-лицензии</a>&ndash;&gt;-->
<!--						</div>-->
<!--					</div>-->
					<div v-if="valueType === radiosType[1]" class="desc mb-4">
						За все действия совершенные в личном кабинете ответственность несет Лицензиат согласно договору-лицензии
					</div>
<!--					<div class="check_policy">-->
<!--						<v-checkbox v-model="check_doc"></v-checkbox>-->
<!--					</div>-->
					<p class="clr_red" v-if="error_validate_doc">Примите согласие на рекламнно информационные
						материалы</p>
					<p class="clr_red" v-if="error_validate_doc_two">Примите согласие на несение ответственности за действия в личном кабинете</p>
					<p class="clr_red" v-if="error_validate">Форма заполнена не верно</p>
					<div @click="sendForm" class="my_btn btn_save">Отправить документы</div>
					<div @click="dialogclose" class="cancel_btn">Отменить</div>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import Icon from '../../SetIcon.vue'
//import FileDrop from '../../FileDrop.vue'
import PhotoLoad from "@/components/PhotoLoad";
import FileLoad from "@/components/FileLoad";

export default {
	components: {
		Icon,
		// FileDrop,
		PhotoLoad, FileLoad
	},
	props: ['title', 'btn', 'dialogstate', 'dialogclose', 'setbar'],
	data() {
		return {
			check_doc: false,
			check_doc_two: true,
			dialog: this.dialogstate,
			radios: [
				'Физическое лицо',
				'Юридическое лицо'
			],
			radiosType: [
				'ООО',
				'ИП'
			],
			// radiosWorker: [
			// 	'Да',
			// 	'Нет'
			// ],
			value: 'Физическое лицо',
			valueType: 'ООО',
			// valueWorker: 'Да',

			CompanyName: "",
			CompanyInn: "",

			pasportOneFile: "",
			pasportTwoFile: "",
			passportAndPersonPhoto: '',

			CompanyFile: "",
			InnFile: '',
			SolutionCompanyFile: '',
			OrderCompanyFile: '',

			Email: '',

			validRadiosForm: {
				individual: false,
				entityOOO: false,
				entityIP: false
			},

			error_validate: false,
			error_validate_doc: false,
			error_validate_doc_two: false,
		}
	},
	watch: {
		dialogstate(nowValue) {
			this.dialog = nowValue
		}
	},
	computed: {
		validCountNumberIP(){
			return 15 === this.CompanyInn.length
		},
		validCountNumberOOO(){
			return 13 === this.CompanyInn.length
		}
	},
	methods: {
		sendForm() {
			if (this.check_doc) {
				this.error_validate_doc = false
				if (this.check_doc_two) {
					let form;
					if (this.value === this.radios[0]) {
						if (this.pasportOneFile
							&& this.pasportTwoFile
							&& this.passportAndPersonPhoto
							&& this.Email
						) {
							form = {
								type_user: this.radios[0],
								pasport_one: this.pasportOneFile,
								pasport_two: this.pasportTwoFile,
								photo_passport_person: this.passportAndPersonPhoto,
								email: this.Email
							}
						} else {
							form = false
							this.errorValid()
							this.validRadiosForm.individual = true
						}

					}
					if (this.value === this.radios[1] && this.valueType === this.radiosType[0]) {

						if (this.CompanyName
							&& this.CompanyInn
							&& this.CompanyFile
							&& this.InnFile
							&& this.SolutionCompanyFile
							&& this.OrderCompanyFile
							&& this.Email
							&& this.validCountNumberOOO
						) {
							form = {
								type_user: this.radios[1],
								type_organization: this.radiosType[0],
								company_name: this.CompanyName,
								company_inn: this.CompanyInn,
								company_file: this.CompanyFile,
								company_inn_file: this.InnFile,
								company_solution: this.SolutionCompanyFile,
								company_order: this.OrderCompanyFile,
								email: this.Email
							}
						} else {
							form = false
							this.errorValid()
							this.validRadiosForm.entityOOO = true
						}


					}
					if (this.value === this.radios[1] && this.valueType === this.radiosType[1]) {

						if (this.CompanyName
							&& this.CompanyInn
							&& this.InnFile
							&& this.pasportOneFile
							&& this.pasportTwoFile
							&& this.Email
							&& this.validCountNumberIP
						) {
							form = {
								type_user: this.radios[1],
								type_organization: this.radiosType[1],
								company_name: this.CompanyName,
								company_inn: this.CompanyInn,
								//company_file: this.CompanyFile,
								company_inn_file: this.InnFile,
								//company_solution: this.SolutionCompanyFile,
								//company_order: this.OrderCompanyFile,
								pasport_one: this.pasportOneFile,
								pasport_two: this.pasportTwoFile,
								email: this.Email
							}
						} else {
							form = false
							this.errorValid()
							this.validRadiosForm.entityIP = true
						}
					}

					if (form != false) {
						this.$http.post("/cabinet/update_userinfo",
							{
								form
							}, (result) => {
								if (result) {
									this.dialog = false
									this.$store.commit('user/setStatus', "waiting")
									this.setbar("waiting")
								}
							})
					}
				} else {
					this.error_validate_doc_two = true
				}
			} else {
				this.error_validate_doc = true
			}
		},
		errorValid(){
			this.error_validate = true;
			setTimeout(() => {
				this.error_validate = false;
			}, 4000)
		},
		pasportOneLoadFile(file) {
			if (file) {
				this.pasportOneFile = file
			} else this.pasportOneFile = ''
		},
		pasportTwoLoadFile(file) {
			if (file) {
				this.pasportTwoFile = file
			} else this.pasportTwoFile = ''
		},
		passportAndPersonPhotoLoad(file){
			if (file) {
				this.passportAndPersonPhoto = file
			} else this.passportAndPersonPhoto = ''
		},
		CompanyLoadFile(file) {
			if (file) {
				this.CompanyFile = file
			} else this.CompanyFile = ''
		},
		InnLoadFile(file) {
			if (file) {
				this.InnFile = file
			} else this.InnFile = ''
		},
		SolutionCompanyLoadFile(file) {
			if (file) {
				this.SolutionCompanyFile = file
			} else this.SolutionCompanyFile = ''
		},
		OrderCompanyLoadFile(file) {
			if (file) {
				this.OrderCompanyFile = file
			} else this.OrderCompanyFile = ''
		},
	}

}
</script>
<style scoped>
.items_wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 24px;
}

.thunder {
	color: red;
}

.valid_value {
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 130%;
	text-align: center;
	width: 100%;
	/*margin-bottom: 24px;*/
	color: #ff3737;
}

.btn_save {
	background: #FEE600;
	cursor: pointer;
	border-radius: 8px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	color: #2B2D33;
	margin-bottom: 0;
}

.label {
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	color: #404247;
	margin-bottom: 6px;
	text-align: left;
}

.clr_red {
	color: red;
}

.type_name {
	font-style: normal;
	font-weight: 700;
	font-size: 17px;
	line-height: 120%;
	color: #404247;
	margin-bottom: 13px;
}

.wrapper {
	padding: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.desc {
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 130%;
	color: #404247;
}

.item_row {
	display: flex;
	margin-bottom: 12px;
	flex-direction: column;
}

.titler {
	text-align: left;
	margin-bottom: 8px;
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 120%;
	color: #404247;
}

.modal_title {
	padding: 0 !important;
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	margin-top: 19px;
	margin-bottom: 24px;
	text-align: center;
	line-height: 130%;
	display: flex;
	align-items: center;
	text-align: center;
	color: #2B2D33;
}



.check_policy_text {
	text-align: left;
	margin-bottom: 15px;
}
</style>
