<template>
    <button :class="align" @click="callback">{{text}}</button>
</template>
<script>
export default {
    props: ['text', 'align','callback']
}
</script>
<style scoped>
    button{
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 120%;
        color: #00809C;
        margin: 2px 0px;
        background: none;
        border:none;
        position: relative;
        padding: 0;
    }
    
    
    button::after{
        background: #66C9D7;
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: -2px;
    }
    .left{
        text-align: left;
    }
    .center{
        text-align: center;
    }
    button:hover{
        cursor: pointer;
        color: #04607D;
    }
    button:hover ::after{
        height: 0;
        background: #00809C;
    }
</style>