import axios from 'axios'
import {store} from '@/store'
import router from '@/router'

class Http {
	constructor(store) {
		this.store = store
		this.axios = axios.create({
			// baseURL: 'http://localhost:3040/api/'
			baseURL: 'https://crm.smsleads.ru/api/'
		})
	}

	test() {
		alert("test")
	}

	post(url, data, cb) {
		if (localStorage.token) data.token = localStorage.token

		this.axios.post(url, data)
			.then(res => {
				cb(res.data)
			})
			.catch(error => {
				console.log('axios error = ' + error)

				store.commit('user/logoutUser')
				router.push('/')

				if (error) cb("error")
			})
	}
}

export const http = Http