<template>
  <div class="timer">
    <div v-if="!finish_timer">
      Получить новый код можно через <span id="time">
        <span class="minutes">{{minutes}}</span>:<span class="seconds">{{seconds}}</span>
      </span>
    </div>
    <ButtonLink v-if="finish_timer" text="Получить новый код" :callback="startTimer"/>
  </div>
</template>
<script>
import ButtonLink from '../Login/ButtonLink.vue'
export default {
  props: ['callback'],
  components: {
    ButtonLink
  },
  data(){
    return {
      finish_timer: false,
      minutes: 2,
      seconds: 50
    }
  },
  methods: {
    startTimer(repeat = true){
      if(repeat){
        this.callback()
        this.minutes = 2
        this.seconds = 59
        this.finish_timer = false
      }
      let interval = setInterval(() => {
        if(this.seconds == 0) {
          this.minutes --
          this.seconds = 60
        }
        this.seconds--
        if(this.seconds < 10) this.seconds = '0' + this.seconds
        if(this.minutes == 0 && this.seconds == 0) {
          clearInterval(interval)
          this.finish_timer = true
        }
      }, 1000)
    }
  },
  beforeMount(){
    this.startTimer(false)
  }
}
</script>