<template> 
  <div class="text-center">
    <v-dialog v-model="dialog" width="510" >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="add">
          <Icon name="plus"/>
          <span>Добавить рассылку</span>
        </div>
      </template>

      <div @click="dialog = false" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
        <div class="modal_title">
          <span>Новая рассылка</span>
        </div>
        <div>
          <p class="modal_subtitle">Название</p>
          <v-text-field v-model="sender.name" placeholder="Тестовая рассылка" :rules="rules"></v-text-field>
        </div>

        <div>
          <p class="modal_subtitle">Тип</p>
          <v-select
            v-model="sender.type"
            :items="items_type"
            item-text="name"
            item-value="type"
            return-object
          ></v-select>
        </div>

        <div class="api" v-if="sender.type.type=='api'">
          <div>
            <p class="modal_subtitle">Разрешенные адреса</p>
            <v-text-field
              v-model="sender.whitelist"
              placeholder="Если у вас несколько адресов, введите через запятую."
            ></v-text-field>
          </div>
          <div>
            <p class="modal_subtitle">Callback URL</p>
            <v-text-field
              v-model="sender.callback"
              placeholder="На какой URL присылать статус отправленных сообщений."
            ></v-text-field>
          </div>
          
        </div>

        <div class="manual" v-if="sender.type.type=='manual'">
          <div>
            <p class="modal_subtitle">База</p>
            <v-select
              v-model="sender.base"
              :items="bases"
              item-text="name"
              item-value="_id"
              return-object
            ></v-select>
          </div>

          <div>
            <p class="modal_subtitle">Имя отправителя</p>
            <v-select
              v-model="sender.alpha"
              :items="alphas"
              item-text="name"
              item-value="_id"
            ></v-select>
          </div>

          <!-- <div>
            <p class="modal_subtitle">Шаблон</p>
            <v-select
              v-model="sender.template"
              :items="templates"
              item-text="name"
              item-value="_id"
              @change="changeTextViaTemplate()"
              return-object
            ></v-select>
          </div> -->

          <div>
            <p class="modal_subtitle">Тэги</p>
            <v-btn text v-for="(item, i) in tags" :key="i" @click="addTag(item)">{{item.name}}</v-btn>
          </div>
          <div class="textarea">
            <p class="modal_subtitle">Текст сообщения</p>
            <v-textarea
              class="my_textarea"
              name="input-7-1"
              rows="5"
              no-resize
              @change="resetTemplate()"
              @input="resetTemplate()"
              v-model="sender.text"
              placeholder="Текст"
            ></v-textarea>
          </div>
        </div>
        <div class="add add_modal" @click="saveSender">
          <span>Добавить рассылку</span>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../../SetIcon.vue'
export default {
  props: ['callback_refresh', 'show_dialog', 'get_sender', 'guid', 'get_tags', 'get_templates', 'get_alphas'],
  components: {
    Icon
  },
  watch: {
    show_dialog: function (val) {
      this.dialog = val
    },
    get_tags: function (val){
      this.tags = val
    },
    get_sender: function (val){
      this.sender = val
    },
    get_templates: function (val){
      this.templates = val
    },
    get_alphas: function (val){
      this.alphas = val
    }
  },
  methods: {
    resetTemplate(){
      this.sender.template = {}
    },
    changeTextViaTemplate(){
      this.sender.text = this.sender.template.text
    },
    addTag(tag){
      this.sender.text += '{{' + tag.slag + '}}'
    },
    saveSender(){
      this.$http.post("/sender/create", this.sender, () => {
        this.dialog = false
        this.callback_refresh()
      })
    }
  },
  data(){
    return {
      dialog: false,
      count:0,
      sender: {
        name: '',
        type: {name: 'API', type: 'api'},
        whitelist: '',
        callback:'',
        text:'',
        alpha: '',
        base:'',
        template:{},
      },
      params:{
        autodelete:false,
        day:10
      },
      tags: [],
      templates: [],
      alphas: [],
      bases:[],
      items_type: [
        {name: 'API', type: 'api'},
        {name: 'Ручная', type: 'manual'}
      ],
      rules: [
        value => !!value || 'Обязательное поле',
        value => (value || '').length <= 20 || 'Max 20 символов'
      ]
    }
  }
}
</script>
<style scoped>
.count
{
width:100%;
text-align:left;
font-size: 13px;
font-weight: 600;
line-height: 24px;  
}
.my_textarea{
  padding-top: 16px;
}
.my_tab_item{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #808185;
  text-transform: none;
}
.my_tab_item:hover{
  color: #2B2D33!important;
  background: none!important;
}
.my_active_tab{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #2B2D33!important;
}
.rules{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  color: #AAABAD;
  text-align: left;
  margin-bottom: 24px;
}
.add_modal{
  width: 100%;
  padding: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
}
.textarea{
  padding: 16px 0;
}
.subtitle{
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  margin-top: 24px;
  color: #404247;
}
.wrapper{
  padding: 32px;
}
.modal_subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  margin-bottom: 6px;
  text-align: left;
  color: #404247;
}
 .add{
    border: none;
    padding: 12px 20px 12px 22px;
    background: #FEE600!important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #2B2D33;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add .icon{
    margin-right: 11px;
  }
  .modal_title{
    padding: 0!important;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2B2D33;
  }
</style>