<template>
	<div class="render">
		<div class="top">
			<h1>Имена отправителей</h1>
			<CreateAlpha :show_dialog="create_popup" @createEvent="cb_create"/>
		</div>
		<div class="content_table">
			<Table
				:server="true"
				:hideselect="true"
				:loading="loading"
				:items="action_items"
				:headers="headers"
				:data="data"
				:perPage="perPage"
				:pageCount_server="1"
				:callback_refresh="renderAlphas"
				@update="updateAlphaEmit"
				@off="offAlphaEmit"
			/>
			<UpdateAlpha :show_dialog="update_popup" :alpha="updateAlpha"/>
		</div>
	</div>
</template>

<script lang="js">
import Table from '../components/Table/DataTable.vue'
import UpdateAlpha from '../components/Modal/Alphas/UpdateAlpha.vue'
import CreateAlpha from '../components/Modal/Alphas/CreateAlpha.vue'

export default {
	name: 'AlphasView',
	components: {
		Table,
		UpdateAlpha,
		CreateAlpha
	},
	mixins: [],
	props: [],
	data() {
		return {
			data: [],
			loading: true,
			update_popup: false,
			create_popup: false,
			headers: [
				// {text: 'ID', value: '_id', width: 100, sortable: false},
				{text: 'Имя', value: 'name', sortable: false},
				{text: 'Обновлено', value: 'updated', sortable: false},
				{text: 'Статус', value: 'statusAlpha', sortable: false},
				{text: 'Стоимость в мес.(руб)', value: 'price', sortable: false},
				{text: 'Действия', value: 'actions', width: 100, sortable: false}
			],
			action_items: [
				{title: 'Обновить', action: 'update'},
				{title: 'Отключить', action: 'off'}
			],
			update_alpha: {
				id: 0,
				item: {}
			},
			perPage: 10
		}
	},
	computed: {},
	watch: {},
	created() {
	},
	methods: {
		cb_create() {
			this.loading = true
			this.renderAlphas()
			this.create_popup = false
		},
		updateAlpha(visible, alpha) {
			this.loading = true
			if (alpha) {
				this.alpha.id = alpha.id
				this.alpha.item = alpha
			}
			this.update_popup = visible
			console.log(visible, alpha)
		},
		renderAlphas() {
			this.$http.post("/alpha/list", {}, data => {
				this.data = data
				this.loading = false
			})
		},
		updateAlphaEmit(item) {
			this.updateAlpha(true, {id: item._id, item})
		},
		offAlphaEmit(item) {
			this.loading = true
			this.$http.post("/alpha/off", {item}, () => {
				this.renderAlphas()
			})
		}
	}
}
</script>
<style lang="sass" scoped>
.top
  margin-bottom: 30px
</style>