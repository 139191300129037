<template> 
  <v-expansion-panels>
    <v-expansion-panel class="my_expand_panel">
      <v-expansion-panel-header>Расширенные настройки</v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="heading">
          <v-checkbox
            label="Автоудаление номеров"
            color="#FEE600"
            @change="input"
            v-model="data.autodelete"
          ></v-checkbox>
        </div>
        <div class="input_wrapper" v-if="data.autodelete">
          <div class="day_title">Хранить дней:</div>
          <div class="minus" @click="data.day--"><Icon name="minus" /></div>
          <v-text-field height="32" @input="input" class="mini_input" min="0" v-model="data.day"></v-text-field>
          <div class="plus" @click="data.day++" ><Icon name="plus" /></div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>   
</template>

<script>
import Icon from '../../../SetIcon.vue'
export default {
  props: ['params'],
  components: {
    Icon
  },
  watch: {
    params: function (val) {
      if (val.day)
      {
        this.data=val
      }
    }
  },
  methods: {
    input()
    {
      this.$emit("updateParams",this.data)
    }
  },
  mounted(){
    if (this.params.day)this.data=this.params
  },
  data(){
    return {
      data:{
        autodelete: false,
        day: 10
      }
    }
  }
}
</script>
<style scoped>
.day_title
{
padding-right: 20px;  
}
.plus{
  border: 1px solid #FEE600;
  border-radius: 8px;
  background: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  cursor: pointer;
  padding: 8px;
}
.mini_input{
  min-width: 33px;
  height: 32px;
  text-align: center;
  padding: 7px 12px;
}
.minus{
  margin-right: 6px;
  border: 1px solid #FEE600;
  border-radius: 8px;
  background: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  padding: 16px 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.input_wrapper{
  display: flex;
  align-items: center;
}
.heading{
  font-style: normal;
font-weight: 600;
font-size: 15px;
width: 100%;
line-height: 120%;
text-align: left;
margin-bottom: 6px;
margin-top: 16px;
color: #404247;
}
.my_expand_panel{
  padding: 0;
}
.my_expand_panel:before{
  box-shadow: none;
  border-radius: none;
}
.my_expand_panel .v-expansion-panel-content{
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.my_expand_panel .v-expansion-panel-header v-expansion-panel-header--active{
  padding: 0;
  min-height: initial;
}
</style>