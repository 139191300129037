<template>
	<div class="render">
		<div class="top">
			<h1>Рассылки</h1>
			<CreateSender :show_dialog="create_popup" :callback_refresh="renderSender" :get_tags="tags" 
				:get_alphas="alphas" :get_templates="templates" :get_sender="sender"/>
		</div>
		<DetailSender :get_sender="sender" :show_dialog="detail_popup" :get_alphas="alphas" :get_templates="templates"
			@close="detail_popup = false"/>
		<div class="content_table">
			<Table
				:server="true"
				:hideselect="true"
				:loading="loading"
				:items="action_items"
				:headers="headers"
				:data="data"
				:perPage="perPage"
				:pageCount_server="1"
				:callback_refresh="renderSender"
				@details="detailSender"
				@stop="stopSender"
				@resume="resumeSender"
			/>
		</div>
	</div>
</template>

<script lang="js">
import Table from '../components/Table/DataTable.vue'
import CreateSender from '../components/Modal/Senders/CreateSender.vue'
import DetailSender from '../components/Modal/Senders/DetailSender.vue'

export default {
	name: 'SendersView',
	components: {
		Table,
		CreateSender,
		DetailSender
	},
	mixins: [],
	props: [],
	data() {
		return {
			data: [],
			loading: true,
			create_popup: false,
			detail_popup: false,
			sender: {},
			tags: [],
			templates: [],
			alphas: [],
			bases: [],
			headers: [
				{text: 'ID', value: '_id', width: 100, sortable: false},
				{text: 'Название', value: 'name', sortable: false},
				{text: 'Тип', value: 'type', sortable: false},
				{text: 'Обновлено', value: 'updated', sortable: false},
				{text: 'Статус', value: 'status', sortable: false},
				{text: 'Действия', value: 'actions', width: 100, sortable: false}
			],
			action_items: [
				{title: 'Подробности', action: 'details'}
			],
			perPage: 10,
		}
	},
	computed: {},
	watch: {},
	created() {
	},
	methods: {
		detailSender(sender) {
			this.sender = sender
			this.detail_popup = true
		},
		stopSender(sender) {
			this.loading = true
			this.$http.post("/sender/stop", sender, () => {
				this.renderSender()
			})
		},
		push_actions(action) {
			if (action == 'active') this.action_items.push({title: 'Остановить', action: 'stop'})
			else this.action_items.push({title: 'Запустить', action: 'resume'})
		},
		resumeSender(sender) {
			this.loading = true
			this.$http.post("/sender/resume", sender, () => {
				this.renderSender()
			})
		},
		renderSender() {
			this.loading = true
			this.$http.post("/sender/list", {}, data => {
				this.data = data
				this.loading = false
			})
			// this.push_actions()
			this.getTags()
			this.getAlphas()
			this.getTemplates()
		},
		getTags() {
			this.$http.post("/tag/list", {}, data => {
				this.tags = data
			})
		},
		getAlphas() {
			this.$http.post("/alpha/list", {}, data => {
				this.alphas = data
			})
		},
		getTemplates() {
			this.$http.post("/template/list", {}, data => {
				this.templates = data
			})
		},
		updateSenderEmit(item) {
			this.updateSender(true, {id: item.id, item})
		}
	}
}
</script>
<style lang="sass" scoped>
.top
  margin-bottom: 30px
</style>