<template>
	<div class="filters">
		<div class="filter">
			<div v-if="page_type=='reports'" class="filter_item">
				<div class="wrap_titler">
					<div class="titler">Выберите тип отчета</div>
					<div v-if="filters.current_scripts" class="clear" @click="filterRequest('select')">
						<Icon name="close"/>
					</div>
				</div>

				<v-select
						class="input_custom mih40"
						height="40"
						hide-details
						item-color="#fee600"
						:items="selects"
						item-value="id"
						v-model="filters.current_scripts"
						item-text="name"
						solo
				></v-select>
			</div>

			<div v-if="page_type=='reports' || 'scripts'" class="filter_item filter_range">
				<div class="wrap_titler">
					<div class="titler">Выберите период</div>
					<div v-if="filters.rangePicker.length" class="clear" @click="filterRequest('range')">
						<Icon name="close"/>
					</div>
				</div>
				<v-menu
						v-model="menu3"
						:close-on-content-click="false"
						:nudge-right="40"
						transition="scale-transition"
						offset-y
						min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<div class="rel" v-bind="attrs" v-on="on">
							<v-text-field
									v-model="dateRangeText"
									readonly
									class="input_custom input_range"
									hide-details
									height="40"
							></v-text-field>
							<Icon class="abs" name="calendar"/>
						</div>
					</template>
					<div class="data_picker__castum">
						<div class="data_picker__castum__menu">
							<v-chip-group
									active-class="primary--text"
									column
							>
								<v-chip
										v-for="(chip,i) in menuDataPicker"
										:key="i"
										column
										label

										@click="datePickerRange(chip.date)"
								>
									{{ chip.name }}
								</v-chip>
							</v-chip-group>
						</div>
						<v-date-picker
								v-model="filters.rangePicker"
								@input="hideRange"
								range
								locale="ru"
								no-title
								first-day-of-week="1"
								color="#000"
								event-color="#00A5BC"
						></v-date-picker>
					</div>
				</v-menu>
			</div>
			<div class="apply" @click="filterRequest()">
				Применить
			</div>
		</div>
	</div>
</template>
<script>
import Icon from '../SetIcon.vue'

export default {
	name: "ReportsFilter",
	props: [
		'page_type', 'c_tab', 'selects', 'callback', 'default'
	],
	data() {
		return {
			menu1: false,
			menu2: false,
			menu3: false,
			filters: {
				startPicker: '',
				finishPicker: '',
				rangePicker: [],
				search: '',
				current_scripts: this.default ?? null
			},
			menuDataPicker: [
				{name: 'Сегодня', date: 'today'},
				{name: 'Вчера', date: 'yesterday'},
				{name: 'Эта неделя', date: 'thisWeek'},
				{name: 'Последние 7 дн.', date: 'Last7Days'},
				{name: 'Этот месяц', date: 'ThisMonth'},
				{name: 'Последние 30 дн.', date: 'Last30Days'}
			],
			countComputed: 0,
		}
	},
	components: {
		Icon
	},
	computed: {
		dateRangeText() {
			let countComputed = this.countComputed
			if (this.filters.rangePicker.length && countComputed >= -1) {
				return this.filters.rangePicker.join(' — ')
			} else {
				return 'За все время'
			}

		},
	},

	methods: {
		datePickerRange(val) {
			let date = new Date()
			if (val != 'yesterday ') {
				this.filters.rangePicker[1] = date.toISOString().split('T')[0]
			}
			switch (val) {
				case 'today':
					this.filters.rangePicker[0] = date.toISOString().split('T')[0]
					break;
				case 'yesterday':
					date.setDate(date.getDate() - 1)
					this.filters.rangePicker[0] = date.toISOString().split('T')[0]
					this.filters.rangePicker[1] = date.toISOString().split('T')[0]
					break;
				case 'thisWeek':
					date.setDate(date.getDate() - (date.getDay() - 1))
					this.filters.rangePicker[0] = date.toISOString().split('T')[0]
					break;
				case 'Last7Days':
					date.setDate(date.getDate() - 6)
					this.filters.rangePicker[0] = date.toISOString().split('T')[0]
					break;
				case 'ThisMonth':
					date.setDate(1)
					this.filters.rangePicker[0] = date.toISOString().split('T')[0]
					break;
				case 'Last30Days':
					date.setDate(date.getDate() - 29)
					this.filters.rangePicker[0] = date.toISOString().split('T')[0]
					break;
				case 'allTime':
					this.filterRequest('range')
					break;
				default:
					break;
			}
			this.hideRange()
			this.countComputed += 1

		},
		hideRange() {
			if (this.filters.rangePicker.length > 1) this.menu3 = false
		},
		today() {
			return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
		},
		filterRequest(clear = false) {
			if (clear) {
				switch (clear) {
					case 'select':
						this.filters.current_scripts = null
						break
					case 'start':
						this.filters.startPicker = ''
						break
					case 'finish':
						this.filters.finishPicker = ''
						break
					case 'range':
						this.filters.rangePicker = []
						break
				}
			} else {
				if (new Date(this.filters.rangePicker[0]) > new Date(this.filters.rangePicker[1])) {
					let per = this.filters.rangePicker[0]
					this.filters.rangePicker[0] = this.filters.rangePicker[1]
					this.filters.rangePicker[1] = per
				}

				this.callback({
					startDate: this.filters.rangePicker[0],
					endDate: this.filters.rangePicker[1],
					filterScript: this.filters.current_scripts
				})
			}
		}
	}
}
</script>
<style scoped>

.wrap_titler {
    display: flex;
    justify-content: space-between;
}

.clear {
    cursor: pointer;
}

.ranger {
    position: absolute;
}

.rel {
    position: relative;
}

.input_range {
    min-width: 250px;
}

.abs {
    position: absolute;
    top: 13px;
    right: 13px;
    cursor: pointer;
}

.search {
    position: relative;
}

.search_btn {
    right: 10px;
    top: 10px;
}

.apply {
    background: #FFF;
    border: 1px solid #FED500;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    color: #2B2D33;
    padding: 10px 20px;
    margin: 24px 30px 0 30px;
    max-height: 40px;
    cursor: pointer;
}

.btn_sort {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 120%;
    color: #00809C;
}

.btn_sort .icon {
    margin-left: 8px;
}

.filter {
    display: flex;
    position: relative;
}

.calls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF4E8;
    padding: 22px 32px;
    border-radius: 8px 8px 0 0;
}

.titler {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    color: #404247;
    text-align: left;
    margin-bottom: 6px;
}

.filter_item {
    position: relative;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
}

.data_picker__castum {
    display: flex;
    /*padding-top: 20px;*/
}

.data_picker__castum__menu {
    background: #FFFFFF;
    width: 200px;
    padding: 0 20px 0 20px;
}

@media (max-width: 800px) {
    .filters {
        justify-content: center;
    }

    .filter {
        flex-direction: column;
    }

    .filter_item {
        margin-top: 10px;
        margin-right: 0;
        margin-left: 0;
    }
}
</style>