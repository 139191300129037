<template>
	<div class="text-center">
		<v-dialog v-model="dialog" width="510">
			<template v-slot:activator="{ on, attrs }">
				<div v-bind="attrs" v-on="on" class="add">
					<Icon name="plus"/>
					<span>Добавить тэг</span>
				</div>
			</template>

			<div @click="dialog = false" class="close">
				<Icon name="close"/>
			</div>
			<v-card class="wrapper">
				<div class="modal_title">
					<span>Новый тэг</span>
				</div>
				<div>
					<p class="modal_subtitle">Название</p>
					<v-text-field v-model="item.name" placeholder="Фамилия" :rules="rules_name"></v-text-field>
				</div>
				<div>
					<p class="modal_subtitle">Slag</p>
					<v-text-field v-model="item.slag" placeholder="surname" :rules="rules_slag"></v-text-field>
				</div>

				<div class="add add_modal" @click="saveTag">
					<span>Сохранить</span>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import Icon from '../../SetIcon.vue'

export default {
	props: ['show_dialog'],
	components: {
		Icon
	},
	methods: {
		saveTag() {
			this.$http.post("/tag/create", {
				name: this.item.name,
				slag: this.item.slag
			}, () => {
				this.$emit('createEvent')
			})
		}
	},
	data() {
		return {
			dialog: false,
			item: {},
			rules_name: [
				value => !!value || 'Обязательное поле',
				value => (value || '').length >= 3 || 'Мин 3 символа'
			],
			rules_slag: [
				value => !!value || 'Обязательное поле',
				value => (value || '').length >= 4 || 'Мин 4 символа'
			]
		}
	},
	watch: {
		show_dialog: function (val) {
			this.dialog = val
		}
	}
}
</script>
<style scoped>
.add_modal {
    width: 100%;
    padding: 9px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 110%;
    color: #2B2D33;
}

.wrapper {
    padding: 32px;
}

.modal_subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    margin-bottom: 6px;
    text-align: left;
    color: #404247;
}

.add {
    border: none;
    padding: 12px 20px 12px 22px;
    background: #FEE600 !important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #2B2D33;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add .icon {
    margin-right: 11px;
}

.modal_title {
    padding: 0 !important;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #2B2D33;
}
</style>