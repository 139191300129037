<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="510" >
      <div @click="dialog = false" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
        <div class="modal_title">
          <span>Обновить</span>
        </div>
        <div>
          <p class="modal_subtitle">Имя</p>
          <v-text-field v-model="name" placeholder="my-name.ru" :rules="rules"></v-text-field>
        </div>
        <div>
          <p class="modal_subtitle">Документы</p>
          <Filedrop />
        </div>
        <div>
          <p class="modal_subtitle">Комментарий</p>
          <v-text-field v-model="comment" placeholder="Если необходимо"></v-text-field>
        </div>
        <div>
          <p class="modal_subtitle">Операторы</p>
          <v-select v-model="operators" :items="items" multiple></v-select>
        </div>

        <div class="add add_modal" @click="saveAlpha">
          <span>Обновить</span>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../../SetIcon.vue'
import Filedrop from '../../FileDrop.vue'
export default {
  props: ['callback_refresh', 'alpha', 'show_dialog'],
  components: {
    Icon,
    Filedrop
  },
  methods: {
    updateData(data) {
      this.params = data
    },
    saveAlpha(id){
      this.$http.post("/alpha/update", {
        id,
        name: this.name,
        comment: this.comment,
        documents: this.documents
      }, () => {
        this.$emit('editEvent')
      })
    }
  },
  data(){
    return {
      items:['MTS', 'Tele2', 'Beeline', 'Megafon', 'Rostelecom'],
      name:'',
      documents: '',
      operators:[],
      comment: '',
      dialog: false,
      edit_popup: false,
      rules: [
        value => !!value || 'Обязательное поле',
        value => (value || '').length <= 11 || 'Max 11 символов'
      ],
    }
  },
  watch: {
    show_dialog: function (val) {
      console.log(666, this.alpha, val)
      this.dialog = val
      if (val) {
        this.name = this.alpha.name
        this.operators = this.alpha.operators
        this.comment = this.alpha.comment
      }
    },
  }
}
</script>
<style scoped>

.add_modal{
  width: 100%;
  padding: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
}
.wrapper{
  padding: 32px;
}
.modal_subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  margin-bottom: 6px;
  text-align: left;
  color: #404247;
}
.add{
  border: none;
  padding: 12px 20px 12px 22px;
  background: #FEE600!important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #2B2D33;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add .icon{
  margin-right: 11px;
}
.modal_title{
  padding: 0!important;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #2B2D33;
}
</style>