<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" >
      <div @click="dialogclose()" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper" v-if="complete">
        <div class="modal_title">
          Спасибо!
        </div>
        <div class="input_wrapper">
          Пожалуйста дождитесь проверки платежа.
        </div>
      </v-card>
      <v-card class="wrapper" v-if="!complete">
        <div class="modal_title">
          Пополнение баланса
        </div>
        <div class="input_wrapper">
          <div class="label">Сумма</div>
          <v-text-field v-model="summ" placeholder="5000₽" type="number"></v-text-field>
        </div>
        <p style="color:red;" v-if="valError">Введите сумму пополнения</p>
        <p style="color:red;" v-if="valErrorDoc">Подтвердите согласие с условиями Оферты</p>
		<div class="check_policy">
			<v-checkbox v-model="check_oferta"></v-checkbox>
			<div class="check_policy_text">
				Подтверждаю своё согласие с условиями <a target="_blank" href="https://crmcalls.ru/wp-content/themes/crmcalls/assets/assets/pdf/soglasie.pdf">Оферты</a>
<!--				Я даю <a target="_blank" href="https://crmcalls.ru/wp-content/themes/crmcalls/assets/assets/pdf/soglasie.pdf">согласие</a> на получение рекламно-информационных материалов от CRMCalls-->
			</div>
		</div>
        <div @click="makeBill()" class="my_btn add_modal">Пополнить</div>
        <div class="my_btn" @click="dialogclose()">Отменить</div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../../SetIcon.vue'
export default {
  components: {
    Icon
  },
  props: ['title', 'btn', 'dialogstate', 'dialogclose', 'opensuccess', 'updatetable'],
  data(){
    return {
      dialog: this.dialogstate,
      summ:0,
      complete:false,
      valError:false,
      valErrorDoc:false,
      check_oferta: false,
    }
  },
  methods:{
    sendForm(){
      let _this = this

      if(this.summ != 0 && this.summ){
        //_this.dialogclose()
        // this.$store.commit('user/setPayments', result)
        if(_this.updatetable) _this.updatetable()
         this.$http.post("/cabinet/set_balance", {
           summ: this.summ
         }, result => {
           if(result){
             //_this.dialogclose()
             this.complete=true
             this.$store.commit('user/setPayments', result)
             if(_this.updatetable) _this.updatetable()
           } else{
             alert("Ошибка сервера")
           }
         })
      } else this.valError = true
    },
    makeBill(){
      if(this.summ != 0 && this.summ && this.check_oferta){

         this.$http.post("/cabinet/make_bill", {
           summ: this.summ
         }, result => {
           console.log('result',result)
           if(result){
             window.open(result, '_blank');
             this.dialogclose()

           } else{
             alert("Ошибка сервера")
           }
         })
      } else {
         if (!this.summ){
           this.valError = true
         } else this.valError = false
         if (!this.check_oferta){
           this.valErrorDoc = true
         } else this.valErrorDoc = false
      }
    }
  },

  watch:{
    dialogstate(nowValue){
      this.dialog = nowValue
    }
  },
}
</script>
<style scoped>
.input_wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.label{
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #404247;
  margin-bottom: 6px;
}
.wrapper{
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.my_btn{
  text-transform: none;
  border: none;
  background: none;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #00809C;
  cursor: pointer;
}
 .add_modal{
  border: none;
  width: 100%;
  padding: 12px 20px 12px 22px;
  background: #FEE600!important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  justify-content: center;
  color: #2B2D33;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-style: normal;
}
.modal_title{
  padding: 0!important;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-top: 19px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2B2D33;
}

.check_policy {
	display: flex;
}

.check_policy_text {
	text-align: left;
    font-size: 15px;
	margin-bottom: 15px;
}
</style>
