<template>
  <DefaultView title-page="Уникальные цены">
    <template v-slot:right-title>
      <BigButton text-btn="Добавить пользователя" icon-btn="plus" @click="openAddModal"/>
    </template>
    <FilterCustom @updateFilter="handleFilterUpdate" :visible="{phone:true,maska:'+7 ### ###-##-##'}"
                  style="margin-bottom: 20px"/>

    <v-expansion-panels>
      <v-expansion-panel
          v-for="(data,i) in (table.filteredData.length > 0 ? table.filteredData : table.data.slice((pagination_number - 1) * 10, pagination_number * 10))"
          :key="i"
      >
        <v-expansion-panel-header>
          <div>{{ data.phone }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="add__button">
            <BigButton text-btn="Добавить" icon-btn="plus" @click="openAddModalWithUserData(data.phone)"/>
          </div>
          <BaseTable :loading="loading" :data="data.prices" :headers="table.headers">
            <template v-slot:action="item">
              <v-list-item @click="changePrice(item, data.phone, data._id)">
                <v-list-item-title>
                  Изменить
                </v-list-item-title>
              </v-list-item>
              <PopconfirmButton @yes="deletePrice(data._id)">
                <v-list-item>
                  <v-list-item-title>
                    Удалить
                  </v-list-item-title>
                </v-list-item>
              </PopconfirmButton>
            </template>
          </BaseTable>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-pagination v-if="table.data.length > 10" :length="Math.ceil(table.data.length / 10)" v-model="pagination_number"
                  :color="'#60c880'"></v-pagination>
    <BaseModal
        :show-modal="popup.add_price"
        @close="closePopup"
        @clickBtn="save"
        :title="valuePopupPrice._id ? 'Редактировать цену' : 'Добавить цену'"
    >
      <WrapperInputs title-inputs="Оператор">
        <v-select
            v-model="valuePopupPrice.operator"
            :items="operators"
            height="44"
        ></v-select>
      </WrapperInputs>
      <WrapperInputs title-inputs="Пользователь">
        <v-select
            v-model="valuePopupPrice.phone"
            :items="phoneNumbers"
            height="44"
        ></v-select>
      </WrapperInputs>
      <WrapperInputs title-inputs="Лимит">
        <v-text-field
            v-model="valuePopupPrice.limit"
            placeholder="Лимит"
            type="number"
        />
      </WrapperInputs>
      <WrapperInputs title-inputs="Цена">
        <v-text-field
            v-model="valuePopupPrice.price"
            placeholder="Цена"
            type="number"
        />
      </WrapperInputs>
    </BaseModal>
  </DefaultView>
</template>

<script>
import DefaultView from "@/components/ContainersView/DefaultView.vue";
import BigButton from "@/components/UI/BigButton.vue";
import BaseTable from "@/components/UI/BaseTable.vue";
import BaseModal from "@/components/UI/BaseModal.vue";
import WrapperInputs from "@/components/UI/WrapperInputs.vue";
import formattingPhoneMixin from "@/mixins/formattingPhoneMixin";
import {mapGetters} from 'vuex'
import PopconfirmButton from "@/components/UI/PopconfirmButton.vue";
import FilterCustom from "@/components/FilterMy.vue";

export default {
  name: "UniquePricesView",
  components: {FilterCustom, PopconfirmButton, WrapperInputs, BaseModal, BaseTable, BigButton, DefaultView},
  mixins: [formattingPhoneMixin],
  data() {
    return {
      table: {
        data: [],
        filteredData: [],
        headers: [
          // {text: 'Пользователь', value: 'phone', sortable: true},
          {text: 'Лимит', value: 'limit', sortable: false},
          {text: 'Оператор', value: 'operator', sortable: false},
          {text: 'Цена', value: 'price', sortable: false},
          {text: 'Действия', value: 'action_menu', sortable: false, width: 200}
        ]
      },
      loading: true,
      popup: {
        add_price: false,
      },
      optionsSelectStatus: [
        {value: 'changePrice', label: 'Редактировать'},
        {value: 'deletePrice', label: 'Удалить'}
      ],
      operators: ['МТС', 'Ростелеком', 'Yota', 'Мегафон', 'Tele2', 'Билайн', 'Мотив', 'КТК-Телеком', 'Прочие операторы'],
      valuePopupPrice: {
        _id: null,
        userId: '',
        phone: '',
        operator: '',
        price: null,
        limit: null,
      },
      userList: [],
      lastFilterNumber: '',
      pagination_number: 1
    }
  },
  methods: {
    async openAddModal() {
      await this.getUserList();
      this.popup.add_price = true;
    },

    async openAddModalWithUserData(phone) {
      await this.getUserList();
      this.valuePopupPrice.phone = phone
      this.popup.add_price = true;
    },

    async getUserList() {
      await this.$http.post("admin/user/list", {}, result => {
            this.userList = this.extractPhoneNumbers(result)
          }
      )
    },

    render() {
      this.$http.post("/admin/uniq-rates/list", {}, result => {
        this.table.data = this.transformData(result)
        this.loading = false
      })
    },

    handleFilterUpdate(filterValue) {
      if (filterValue.search) {
        const result = this.searchByPhoneNumber(filterValue.search.replace(/[+\s-]/g, ''));
        this.table.filteredData = result ? [result] : [];
        this.lastFilterNumber = filterValue.search.replace(/[+\s-]/g, '')
      } else {
        this.table.filteredData = []
        this.lastFilterNumber = ''
      }
    },

    searchByPhoneNumber(fullPhoneNumber) {
      for (const item of this.table.data) {
        if (item.phone === fullPhoneNumber) {
          return item;
        }
      }
      return null;
    },

    transformData(inputData) {
      const transformedData = [];
      inputData.forEach(item => {
        const existingItem = transformedData.find(dataItem => dataItem.senderId === item.senderId);

        if (existingItem) {
          existingItem.prices.push({
            operator: item.operator,
            price: item.price,
            limit: item.limit
          });
        } else {
          const newItem = {
            _id: item._id,
            phone: item.phone,
            senderId: item.senderId,
            prices: [{
              operator: item.operator,
              price: item.price,
              limit: item.limit
            }],
            updated: item.updated,
            __v: item.__v
          };

          transformedData.push(newItem);
        }
      });

      return transformedData;
    },

    changePrice(item, phone, id) {
      const {operator, limit, price} = item.item
      if (this.userList.length <= 0) {
        this.getUserList()
      }
      this.valuePopupPrice._id = id
      this.valuePopupPrice.phone = phone
      this.valuePopupPrice.operator = operator
      this.valuePopupPrice.limit = limit
      this.valuePopupPrice.price = price
      this.popup.add_price = true
    },

    deletePrice(id) {
      this.loading = true
      this.$http.post("/admin/uniq-rates/delete", {_id: id}, result => {
        console.log("deleted - ", result)
        this.render()
      })
    },

    async save() {
      this.loading = true
      let data = {
        phone: this.valuePopupPrice.phone,
        operator: this.valuePopupPrice.operator,
        limit: this.valuePopupPrice.limit,
        price: this.valuePopupPrice.price,
        senderId: this.userList.find(item => item.phone === this.valuePopupPrice.phone)._id
      }

      await this.$http.post("/admin/uniq-rates/addOrUpdate", data, () => {
        this.closePopup()
        this.render()
        if (this.table.filteredData.length > 0) {
          const filterValue = {
            search: data.phone
          };
          this.handleFilterUpdate(filterValue)
        }
        this.loading = false
      })
    },

    closePopup() {
      this.popup.add_price = false
      this.valuePopupPrice = {
        _id: null,
        userId: '',
        operator: '',
        price: null,
        limit: null,
      }
    },
  },
  mounted() {
    this.render()
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/getUserFields'
    }),
    phoneNumbers() {
      return this.userList.map(user => user.phone);
    }
  },
  watch: {
    'table.data': {
      handler() {
        if (this.lastFilterNumber !== '') {
          const filterValue = {
            search: this.lastFilterNumber
          }
          this.handleFilterUpdate(filterValue)
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.v-expansion-panels {
  gap: 10px;
}
</style>