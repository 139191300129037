<template>
	<DefaultView title-page="Альфы">
		<FilterCustom @updateFilter="onUpdateFilter" :visible="{phone:true,maska:'+7 ### ###-##-##'}"/>
		<BaseTable
			:loading="loading"
			:headers="table.headers"
			:data="table.filterData"
		>
			<template v-slot:status="{ item }">
				<SelectTable :value="item.status"
					:options="optionsSelectStatus"
					@update="val => onUpdate(val, item)"
				/>
			</template>
			<template v-slot:document="{ item }">
				<a :value="item.documents"
					:href="item.documents"
					target="_blank"
					icon
					small
					color="indigo">
						<v-icon>mdi-download</v-icon>
				</a>
			</template>

			<template v-slot:[`operators`]="{item}">
				<div v-for="val in item.operators" :key="val">
					<div class="operator_ico" v-if="val == 'MTS'">
						<Icon  name="op_mts"/>
						MTS
					</div>
					<div class="operator_ico" v-if="val == 'Beeline'">
						<Icon name="op_beeline"/>
						Beeline
					</div>
					<div class="operator_ico" v-if="val == 'Megafon'">
						<Icon name="op_megafon"/>
						Megafon
					</div>
					<div class="operator_ico" v-if="val == 'Tele2'">
						<Icon name="op_tele"/>
						Tele2
					</div>
					<div class="operator_ico" v-if="val == 'Tinkof'">
						<Icon name="op_tinkoff"/>
						Tinkof
					</div>
					<div class="operator_ico" v-if="val == 'Yota'">
						<Icon name="op_yota"/>
						Yota
					</div>	
				</div>
			</template>

			<template v-slot:price="{ item }">
                <v-edit-dialog
					:return-value.sync="item.price"
					@save="savePrice(item)"
					
				> {{item.price}}
					<template v-slot:input>
					<v-text-field
						v-model="item.price"
						label="Редактировать"
						single-line
					></v-text-field>
					</template>
				</v-edit-dialog>
            </template>
		</BaseTable>
	</DefaultView>
</template>

<script>
import DefaultView from "@/components/ContainersView/DefaultView.vue"
import FilterCustom from '../../components/FilterMy.vue'
import BaseTable from "@/components/UI/BaseTable.vue"
import SelectTable from "@/components/UI/SelectTable.vue"
import Icon from '../../components/SetIcon.vue'

export default {
	name: "AlphasAdminView",
	components: {
		DefaultView,
		FilterCustom,
		BaseTable,
		SelectTable,
		Icon
	},
	data() {
		return {
			optionsSelectStatus: [
				{value: 'wait', label: 'Ожидает проверки'},
				{value: 'active', label: 'Одобрено'},
				{value: 'unactive', label: 'Отклонено'},
				{value: 'off', label: 'Отключена'}
			],
			colorSelect: {
				active: 'status_success',
				waiting: 'status_wait',
				unactive: 'status_stop',
				off: 'status_stop'
			},
			table: {
				data: [],
				filterData: [],
				headers: [
					{text: 'Название', value: 'name', sortable: false},
					{text: 'Цена', value: 'price', sortable: false},
					{text: 'Операторы', value: 'operators', sortable: false},
					{text: 'Статус', value: 'status', width: 350, sortable: false},
					{text: 'Коммент', value: 'comment', sortable: false},
					{text: 'Документ', value: 'document', sortable: false}
				]
			},
			users:[],
			loading: true,
			filters_data_users: false
		}
	},
	methods: {
		render() {
			this.$http.post("/admin/alpha/list", {}, data => {
				this.table.data = data
				this.loading = false
				this.filterDataUsers()
			})
		},
		getUsers(){
			this.$http.post("/admin/user/list", {}, data => {
				this.users = data
				this.filterDataUsers()
			})
		},
		savePrice(item){
			this.loading = true
			this.$http.post("/admin/alpha/update", {_id: item._id, price: item.price}, () => {
				this.render()
			})
		},
		onUpdateFilter(new_filter) {
			this.filters_data_users = new_filter
			this.filterDataUsers()
		},
		onUpdate(val, item) {
			this.loading = true
			this.$http.post("/admin/alpha/update", {_id: item._id, status: val}, () => {
				this.render()
			})
		},
		filterDataUsers() {
			let newData = this.table.data
			if (this.filters_data_users.search) {
				newData = newData.filter(user => user.phone.includes(this.filters_data_users.search))
			}
			if (this.filters_data_users.searchNickname) {
				newData = newData.filter(user => {
					if (user.nickname) return user.nickname.includes(this.filters_data_users.searchNickname)
					else return false
				})
			}
			// console.log(newData)
			this.table.filterData = newData
		}
	},
	mounted() {
		this.render()
	}
}
</script>

<style scoped>
.operator_ico{
	display: flex;
}
</style>