<template>
	<div class="render">
		<v-breadcrumbs class="bc" v-if="!if_main" :items="breadcrumbs" large>
			<template v-slot:item="{ item }">
				<v-breadcrumbs-item
					@click="returnMain"
					:disabled="item.disabled"
				>
					{{ item.text }}
				</v-breadcrumbs-item>
			</template>
		</v-breadcrumbs>
		<div class="top">
			<h1>{{ current.title }}</h1>
			<CreateBase v-if="if_main" type="create" :callback_refresh="renderBases"/>
		</div>
		<div class="content_table">
			<Table v-if="if_main"
				page_type="base_main"
				:callback_delete="deleteBase"
				:items="action_items"
				:loading="loading"
				:hideselect="true"
				:perPage="perPage"
				:server="false"
				:callback_refresh="renderBases"

				@delete="deleteBase"
				@edit="editBaseEmit"
				@view="viewBase"

				:callback_edit="editBase"
				:callback_view="viewBase"

				:headers="cases.main.headers"
				:data="cases.main.data"
			/>
			
			<Table v-else
				page_type="base_phones"
				:loading="loading"
				:server="true"
				:hideselect="true"
				:perPage="perPage"
				:items="action_items_server"
				:headers="cases.all.headers"
				:pageCount_server="cases.all.pageCount_server"
				:callback_edit="editBase"
				:callback_view="viewBase"
				:callback_refresh="renderBases"
				:data="cases.all.data"
			/>
			
		</div>

	</div>
</template>
<script>

import Table from '../components/Table/DataTable.vue'
import CreateBase from '../components/Modal/Bases/CreateBase.vue'


export default {
	components: {
		Table, CreateBase
	},
	data() {
		return {

			if_main: true,
			filters: {},
			loading: true,
			perPage: 10,
			tablist: "all",
			edit_popup: false,
			edit_base: {
				id: 0,
				item: {}
			},
			breadcrumbs: [
				{
					text: 'Базы номеров',
					disabled: false,
					click: 'index'
				},
				{
					text: 'Link 2',
					disabled: true,
					click: false,
				}
			],
			current: {
				title: ''
			},
			default: {
				title: 'Базы номеров'
			},
			detail: {
				title: 'Какая то база номеров A1'
			},
			
			action_items: [
				{title: 'Посмотреть номера', action: 'view'},
				{title: 'Удалить', action: 'delete'},
			],
			cases: {
				main: {
					data: [
						{
							id: '123',
							name: 'test',
							count: 1,
							unique: 'true',
							created_at: 'asdasdasd'
						},
						{
							id: '122',
							name: 'vfvf',
							count: 1,
							unique: 'true',
							created_at: 'asdasdasd'
						}
					],
					headers: [
						{text: '№', align: 'start', value: 'id', sortable: false},
						{text: 'Название', value: 'name', sortable: false},
						{text: 'Количество номеров', value: 'count', sortable: false},
						{text: 'Дата создания', value: 'created_at', sortable: false},
						{text: 'Действия', value: 'actions', sortable: false}
					]
				},
				all: {
					headers: [
						{text: 'Телефон', value: 'phone'}
					],
					data: [],
					pageCount_server: 0
				}
			}
		}
	},
	beforeMount() {
		this.current = this.default
		this.renderBases()
	},
	methods: {
		deleteBase(item) {
			console.log(item)
			// this.$http.post("/base/delete", {id: item.guid}, data => {
			// 	this.renderBases()
			// 	return data
			// })
		},

		returnMain() {
			this.if_main = true
			this.reset()
			this.renderBases()
		},

		renderBases(filters = false) {
			this.loading = true
			console.log(this.if_main, 'this.if_main')
			console.log(filters)
			if (this.if_main) {
				// this.$http.post("/base/list", {}, data => {
				// 	this.loading = false
				// 	this.cases.main.data = data
				// })
			} else {
				if (!filters) filters = this.filters

				filters.blacklist = 0
				if (this.tablist == "black") filters.blacklist = 1

				filters.perPage = this.perPage
				this.filters = filters

				// this.$http.post("/base/leads", {
				// 	filters: filters,
				// 	id: this.id
				// }, data => {
				// 	this.loading = false
				// 	this.cases[this.tablist].data = data.leads
				// 	if (data.count) this.cases[this.tablist].pageCount_server = data.count

				// })


				this.cases[this.tablist].data = ['123123123', '123123343'].map(phone => ({phone: phone}))
				this.cases[this.tablist].headers = [
					{text: 'Телефон', value: 'phone'}
				]
				this.cases[this.tablist].pageCount_server = 1
				this.loading = false
			}
			this.loading = false
		},
		viewBase(item) {
			let guid = item.guid
			let name = item.name

			this.id = guid
			this.current.title = name
			this.breadcrumbs[1].text = name
			this.if_main = false
			this.renderBases()
		},
		reset() {
			this.current.title = "Базы номеров"
		}
	},
}
</script>
<style scoped>
.tabs_table_inner .v-slide-group__content {
    margin: 0 16px;
}

.item_tab {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    color: #808185;
}

.active_tab {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    color: #2B2D33;
}

.v-list div {
    cursor: pointer;
}

.content_table {
    margin-top: 24px;
    border-radius: 8px;
}

.content {
    height: 100%;
    display: flex;
    position: relative;
    z-index: 1000;
}

@media (max-width: 650px) {
    .top {
        flex-direction: column;
    }

    h1 {
        margin-bottom: 20px;
    }

}

@media (max-width: 400px) {
    h1 {
        margin-top: 15px;
    }
}
</style>