export default {
	methods: {
		phoneCustom(phone) {
			if (phone) return String(phone).replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/g, `$1 ($2) $3-$4-$5`)
		},
		reversePhoneToDb(phone) {
			if (phone) return String(phone).replace(/\D/g, '')
		},
		extractPhoneNumbers(data) {
			if (!Array.isArray(data)) {
				throw new Error('Input data should be an array');
			}
			// const phoneNumbers = data.map(item => {
			// 	if (item && item.phone) {
			// 		return item.phone;
			// 	}
			// 	return null;
			// });
			// return phoneNumbers.filter(phone => phone !== null);
			const phoneObjects = data
				.filter(item => item && item.phone && item._id)
				.map(item => ({
					phone: item.phone,
					_id: item._id
				}));

			return phoneObjects;
		}
	}
}


