<template>
	<div class="render">
		<div class="top">
			<h1>Тэги</h1>
			<CreateTag :show_dialog="create_popup" @createEvent="cb_create"/>
		</div>
		<div class="content_table">
			<Table
				:server="true"
				:hideselect="true"
				:loading="loading"
				:items="action_items"
				:headers="headers"
				:data="data"
				:perPage="perPage"
				:pageCount_server="1"
				:callback_refresh="renderTag"
				@edit="editTagEmit"
				@delete="deleteTagEmit"
			/>
		</div>
		<EditTag :show_dialog="edit_popup" @editEvent="cb_edit" :edit_tag="edit_tag" @close="edit_popup = false"/>
	</div>
</template>

<script lang="js">
import Table from '../components/Table/DataTable.vue'
import CreateTag from '../components/Modal/Tags/CreateTag.vue'
import EditTag from '../components/Modal/Tags/EditTag.vue'

export default {
	name: 'TagsView',
	components: {
		Table,
		EditTag,
		CreateTag
	},
	mixins: [],
	props: [],
	data() {
		return {
			data: [],
			count: 0,
			loading: true,
			edit_popup: false,
			create_popup: false,
			headers: [
				{text: 'Название', value: 'name', sortable: false},
				{text: 'Slag', value: 'slag', sortable: false},
				{text: 'Действия', value: 'actions', width: 100, sortable: false}
			],
			action_items: [
				{title: 'Редактировать', action: 'edit'},
				{title: 'Удалить', action: 'delete'}
			],
			edit_tag: false,
			perPage: 10,
		}
	},
	methods: {
		cb_edit() {
			this.loading = true
			console.log('cb_edit')
			this.renderTag()
			this.edit_popup = false
		},
		cb_create() {
			this.loading = true
			console.log('cb_create')
			this.renderTag()
			this.create_popup = false
		},
		editTag(tag, edit) {
			if (edit) this.edit_tag = tag
			this.edit_popup = true
		},
		renderTag() {
			this.$http.post("/tag/list", {}, data => {
				this.data = data
				this.loading = false
			})
		},
		editTagEmit(item) {
			this.editTag({id: item._id, item}, true)
		},
		deleteTagEmit(item) {
			this.loading = true
			this.$http.post("/tag/delete", item, () => {
				this.renderTag()
			})
		}
	}
}
</script>
<style lang="sass" scoped>
h1
  margin-bottom: 30px
</style>