<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="510" >
      <div @click="close" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
        <div class="modal_title">
          <span>Детали рассылки</span>
        </div>
        <div>
          <p class="modal_subtitle">Название рассылки</p>
          <p class="value">{{sender.name}}</p>
        </div>
        <div>
          <p class="modal_subtitle">Тип</p>
          <p v-if="sender.type == 'api'" class="value">API</p>
          <p v-else class="value">Ручная</p>
        </div>

        <div v-if="sender.type == 'api'" class="api" >
          <div>
            <p class="modal_subtitle">Разрешенные адреса</p>
            <p class="value">{{sender.whitelist}}</p>
          </div>
          <div>
            <p class="modal_subtitle">Callback URL</p>
            <p class="value">{{sender.callback}}</p>
          </div>
          <p class="modal_subtitle">API (Basic Auth)</p>
          <div>
            <p class="modal_subtitle">Логин</p>
            <p class="value">{{sender.api.login}}</p>
          </div>
          <div>
            <p class="modal_subtitle">Пароль</p>
            <p class="value">{{sender.api.password}}</p>
          </div>
        </div>

        <div v-else class="manual" >
          <div v-for="(item, i) in bases" :key="i">
            <div v-if="item._id == sender.base">
              <p class="modal_subtitle">База</p>
              <p class="value">{{item.name}}</p>
            </div>
          </div>

          <div v-for="(item, i) in alphas" :key="i">
            <div v-if="item._id == sender.alpha">
              <p class="modal_subtitle">Имя отправителя</p>
              <p class="value">{{item.name}}</p>
            </div>
          </div>

          <div v-for="(item, i) in templates" :key="i">
            <div v-if="item._id == sender.template">
              <p class="modal_subtitle">Шаблоны</p>
              <p class="value">{{item.name}}</p>
            </div>
          </div>

          <div class="textarea">
            <p class="modal_subtitle">Текст сообщения</p>
            <p class="value">{{sender.text}}</p>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../../SetIcon.vue'
export default {
  props: ['callback_refresh', 'guid', 'show_dialog', 'get_sender', 'get_alphas', 'get_templates'],
  components: {
    Icon
  },
  watch: {
    show_dialog: function (val) {
      this.dialog = val
    },
    get_sender: function (val){
      this.sender = val
    },
    get_templates: function (val){
      this.templates = val
    },
    get_alphas: function (val){
      this.alphas = val
    }
  },
  methods: {
    close(){
      this.dialog = false;
      this.$emit('close')
    }
  },
  data(){
    return {
      dialog: false,
      templates: [],
      alphas: [],
      bases: [],
      sender:{},
      params:{
        autodelete:false,
        day:10
      }
    }
  }
}
</script>
<style scoped>
.count
{
  width:100%;
  text-align:left;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
}
.my_textarea{
  padding-top: 16px;
}
.my_tab_item{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #808185;
  text-transform: none;
}
.my_tab_item:hover{
  color: #2B2D33!important;
  background: none!important;
}
.my_active_tab{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #2B2D33!important;
}
.rules{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  color: #AAABAD;
  text-align: left;
  margin-bottom: 24px;
}
.add_modal{
  width: 100%;
  padding: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
}
.textarea{
  padding: 16px 0;
}
.subtitle{
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  margin-top: 24px;
  color: #404247;
}
.wrapper{
  padding: 32px;
}
.modal_subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  margin-bottom: 6px;
  text-align: left;
  color: #404247;
}
.add{
  border: none;
  padding: 12px 20px 12px 22px;
  background: #FEE600!important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #2B2D33;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add .icon{
  margin-right: 11px;
}
.modal_title{
  padding: 0!important;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2B2D33;
}
</style>