<template>
	<DefaultView title-page="Каналы">
		<template v-slot:right-title>
			<BigButton text-btn="Добавить" icon-btn="plus" @click="popup = true"/>
		</template>
		<BaseTable
			:loading="loading"
			:headers="headers"
			:data="data"
			:perPage="perPage"
			:pageCount_server="1"
			:callback_refresh="render"
			@edit="editChannelEmit"
			@delete="deleteChannel"
		>
			<template v-slot:action="{item}">
				<v-list-item @click="editChannelEmit(item)">
					<v-list-item-title>
						Изменить
					</v-list-item-title>
				</v-list-item>
				<v-list-item @click="deleteChannel(item)">
					<v-list-item-title>
						Удалить
					</v-list-item-title>
				</v-list-item>
			</template>

			<template v-slot:[`operators`]="{item}">
				<div v-for="val in item.operators" :key="val">
					<div class="operator_ico" v-if="val == 'MTS'">
						<Icon  name="op_mts"/>
						MTS
					</div>
					<div class="operator_ico" v-if="val == 'Beeline'">
						<Icon name="op_beeline"/>
						Beeline
					</div>
					<div class="operator_ico" v-if="val == 'Megafon'">
						<Icon name="op_megafon"/>
						Megafon
					</div>
					<div class="operator_ico" v-if="val == 'Tele2'">
						<Icon name="op_tele"/>
						Tele2
					</div>
					
				</div>
			</template>
			<template v-slot:[`status`]="{item}">
				<div v-for="value in item.status" :key="value">
					<v-chip v-if="value==0"
						class="ma-2"
						color="red"
						text-color="white"
						>
						Остановлено
					</v-chip>
					<v-chip v-if="value==1"
						class="ma-2"
						color="green"
						text-color="white"
						>
						Работает
					</v-chip>
				</div>
			</template>
			<template v-slot:[`alphas`]="{item}">
				<div v-for="value in item.alphas" :key="value">
					<v-chip class="ma-2">
						{{ value }}
					</v-chip>
				</div>
			</template>
		</BaseTable>
		<BaseModal
			:show-modal="popup"
			@close="closePopup"
			@clickBtn="save"
			:title="valuePopupChannel._id ? 'Редактировать канал' : 'Добавить канал'"
		>
			<WrapperInputs title-inputs="Оператор">
				<v-select
					v-model="valuePopupChannel.operator"
					:items="operators"
				></v-select>
			</WrapperInputs>
			<WrapperInputs title-inputs="Канал">
				<v-select
					v-model="valuePopupChannel.channel"
					:items="channels"
				/>
			</WrapperInputs>
			<pre>{{ alphas }}</pre>
			<WrapperInputs title-inputs="Альфы">
				<v-select
					v-model="valuePopupChannel.alphas"
					:items="alphas"
		
					multiple
				/>
			</WrapperInputs>
			<WrapperInputs title-inputs="Статус">
				<v-select
					v-model="valuePopupChannel.status"
					:items="statuses"
					item-value="value"
					item-title="name"
				/>
			</WrapperInputs>
			<WrapperInputs title-inputs="Цена">
				<v-text-field
					v-model="valuePopupChannel.price"
					placeholder="Цена"
					type="number"
				/>
			</WrapperInputs>
		</BaseModal>
	</DefaultView>
</template>

<script lang="js">
import DefaultView from "@/components/ContainersView/DefaultView.vue"
import BaseTable from "@/components/UI/BaseTable.vue"
import BaseModal from "@/components/UI/BaseModal.vue";
import BigButton from "@/components/UI/BigButton.vue";
// import SelectTable from "@/components/UI/SelectTable.vue"
import WrapperInputs from "@/components/UI/WrapperInputs.vue";

export default {
	name: 'ChannelsView',
	components: {
		DefaultView,
		BaseTable,
		BaseModal,
		BigButton,
		WrapperInputs,
		// SelectTable
	},
	mixins: [],
	props: [],
	data() {
		return {
			data: [],
			loading: true,
			popup: false,
			valuePopupChannel: {
				operator: null,
				price: null,
				alphas: null,
				status: null,
				channel: null
			},
			channels:['mts'],
			statuses: [0,1],
			operators: ['mts', 'megafon', 'tele2', 'beeline', 'others'],
			optionsSelect: [
				{value: 'changePrice', label: 'Редактировать'},
				{value: 'deleteChannel', label: 'Удалить'}
			],
			channel: {},
			alphas: [],
			headers: [
				{text: 'Оператор', value: 'operator', sortable: false},
				{text: 'Канал', value: 'channel', sortable: false},
				{text: 'Цена', value: 'price', sortable: false},
				{text: 'Альфы', value: 'alphas', sortable: false},
				{text: 'Статус', value: 'status', sortable: false},
				{text: 'Действия', value: 'action_menu', sortable: false}
			],
			perPage: 10,
		}
	},
	computed: {},
	watch: {},
	created() {
	},
	mounted() {
		this.render()
	},
	methods: {
		deleteChannel(item) {
			this.$http.post("/admin/channel/delete", item, () => {
				this.render()
			})
		},
		closePopup() {
			this.popup = false
			this.valuePopupChannel = {
				_id: null,
				operator: null,
				price: null,
				alphas: null,
				status: null,
				channel: null
			}
		},
		save() {
			this.loading = true
			let data = {
				operator: this.valuePopupChannel.operator,
				status: this.valuePopupChannel.status,
				price: this.valuePopupChannel.price,
				channel: this.valuePopupChannel.channel,
				alphas: this.valuePopupChannel.alphas
			}
			if (this.valuePopupChannel._id) data._id = this.valuePopupChannel._id

			this.popup = false
			this.$http.post("/admin/channel/update", this.valuePopupChannel, () => {
				this.render()
			})

			this.closePopup()
		
		},
		render() {
			this.loading = true
			this.$http.post("/admin/channel/list", {}, data => {
				this.data = data
				this.loading = false
				this.popup = false
			})
			this.getAlphas()
		},

		getAlphas() {
			this.$http.post("/admin/alpha/list-active", {}, data => {
				this.alphas = data
			})
		},
		editChannelEmit(item){
			this.valuePopupChannel = item
			this.popup = true
		}
	}
}
</script>
<style lang="sass" scoped>
.top
  margin-bottom: 30px
</style>