<template>
	<v-menu offset-y offset-overflow left>
		<template v-slot:activator="{ on, attrs }">
			<span v-bind="attrs" v-on="on">
				<slot></slot>
			</span>
		</template>
		<v-list style="padding: 0;">
			<v-list-item style="padding: 0;">
				<v-sheet
					class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
					color="blue-grey darken-3"
					dark
				>
					<div class="grey--text text--lighten-1 text-body-2 mb-4">
						{{ question }}
					</div>

					<v-btn
						class="ma-1"
						color="grey"
						plain
					>
						{{ closeBtn }}
					</v-btn>

					<v-btn
						class="ma-1"
						color="error"
						plain
						@click="$emit('yes')"
					>
						{{ yesBtn }}
					</v-btn>
				</v-sheet>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
export default {
	name: "PopconfirmButton",
	props: {
		question: {
			type: String,
			default: 'Подтвердите удаление'
		},
		closeBtn: {
			type: String,
			default: 'Отмена'
		},
		yesBtn: {
			type: String,
			default: 'Удалить'
		}
	}
}
</script>

<style scoped>

</style>