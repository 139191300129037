<template>
	<div class="render">
		<h1>Профиль</h1>
		<div class="anket">
			<div class="user">
				<h2>Основные настройки</h2>
				<v-text-field label="Имя" v-model="user.name"></v-text-field>
				<v-text-field label="Электронная почта" v-model="user.email"></v-text-field>
				<v-text-field label="Номер телефона" type="tel" disabled v-maska="'+7 ### ###-##-##'" v-model="user.phone"></v-text-field>
				<ChangePassword />
				
				<v-switch
					label="Уведомления"
					v-model="user.notifications"
				></v-switch>
				<v-switch
					v-model="user.two_factor"
					label="Двухфакторная аутентификация"
				></v-switch>
				<v-select 
					multiple 
					v-model="user.low_balance" 
					label="Оповещения при низком балансе" 
					:items="['sms', 'email']"
				></v-select>
				<v-btn @click="save_user">Сохранить</v-btn>
			</div>
			<v-divider></v-divider>
			<div class="company" >
				<div class="flex">
					<h2>Реквизиты компании</h2>
					<company-data :data_org="data_org" :cb="cb_org" />
				</div>
				<div class="data_org" v-if="data_org">
					<v-checkbox v-model="data_org.use_for_pay" label="Использовать для оплаты"></v-checkbox>
					<p>Эти реквизиты будут использоваться для выставления счетов</p>
					<v-text-field 
						label="Наименование"
						v-model="data_org.org_name">
					</v-text-field>
					<v-text-field 
						label="Название должности руководителя"	
						v-model="data_org.manager_position_title">
					</v-text-field>
					<v-text-field 
						label="ФИО Руководителя"
						v-model="data_org.full_name">
					</v-text-field>
				
					<v-text-field 
						label="В каком банке"
						v-model="data_org.bank_name">
					</v-text-field>
					<div class="flex">
						<v-text-field 
							label="ИНН"
							v-model="data_org.inn">
						</v-text-field>
						<v-text-field 
							label="КПП"
							v-model="data_org.kpp">
						</v-text-field>
					</div>
					<div class="flex">
						<v-text-field 
							label="Расчетный счёт"
							v-model="data_org.checking_account">
						</v-text-field>
						<v-text-field 
							label="Корреспондентский счёт"
							v-model="data_org.correspondent_account">
						</v-text-field>
					</div>
					<v-text-field 
						label="БИК"
						v-model="data_org.bik">
					</v-text-field>
					<v-text-field 
						label="Юридический адрес"
						v-model="data_org.legal_address">
					</v-text-field>
					<v-text-field 
						label="Почтовый адрес для отправки бухгалтерских документов"
						v-model="data_org.postal_address_for_sending_accounting_documents">
					</v-text-field>
					<v-text-field 
						label="Контактные данные заказчика"
						v-model="data_org.email">
					</v-text-field>
					<v-btn @click="save_org">Сохранить</v-btn>
					<v-btn @click="delete_org">Удалить реквизиты</v-btn>
				</div>
			</div>
		</div>
		<div v-if="!history_hide && breadActive === 0" class="content_table">
			<div class="history_title">История баланса</div>
			<div class="desc">Здесь отображены все денежные операции вашего аккаунта</div>
			<Table :headers="headers" :data="dataPayments" :hideselect="true"/>
		</div>
		<!-- <SuccessBalance :dialogstate="showSuccessBalance" :dialogclose="closeSuccessBalance"/> -->
		<pdf />
	</div>
</template>
<script>
// import SuccessBalance from '../components/Modal/Cabinet/BalanceSuccess.vue'
import Table from '../components/Table/DataTable.vue'
import CompanyData from '@/components/Modal/Cabinet/CompanyData.vue'
import ChangePassword from '../components/Modal/Cabinet/ChangePassword.vue'
import pdf from '../components/PdfGenerate.vue'
export default {
	components: {
		// SuccessBalance,
		Table,
		CompanyData,
		ChangePassword,
		pdf
	},
	data() {
		return {
			history_hide: true,
			user: {
				low_balance: this.$store.getters['user/getUserFields'].low_balance,
				email: this.$store.getters['user/getEmail'],
				balance: this.$store.getters['user/getUserFields'].balance,
				phone: this.$store.getters['user/getUserFields'].phone,
				name: this.$store.getters['user/getUserFields'].name,
				two_factor: this.$store.getters['user/getUserFields'].two_factor,
				notifications: this.$store.getters['user/getUserFields'].notifications
			},
			data_org: this.$store.getters['user/getUserFields'].org,
			headers: [
				{text: '№', align: 'start', value: 'id'},
				{text: 'Тип операции', value: 'type'},
				{text: 'Сумма', value: 'sum'},
				{text: 'Дата пополнения', value: 'date'},
				{text: 'Статус', value: 'status'}
			],
			dataPayments: this.$store.getters['user/getUserFields'].payments,
			showSuccessBalance: false,
			breadActive: 0
		}
	},
	methods: {
		save_org(){
			this.$http.post("/user/org", this.data_org, () => {
				console.log('success')
			})
		},
		delete_org(){
			this.data_org = {}
			this.$http.post("/user/org", this.data_org, () => {
				console.log('success')
			})
		},
		cb_org(data){
			this.data_org = {
				org_name: data.items[0]['ЮЛ']['НаимПолнЮЛ'],
				manager_position_title: data.items[0]['ЮЛ']['Руководитель']['Должн'],
				full_name: data.items[0]['ЮЛ']['Руководитель']['ФИОПолн'],
				bank_name: '',
				inn: data.items[0]['ЮЛ']['ИНН'],
				kpp: data.items[0]['ЮЛ']['КПП'],
				checking_account: '',
				correspondent_account: '',
				bik: '',
				legal_address: data.items[0]['ЮЛ']['Адрес']['АдресПолн'],
				postal_address_for_sending_accounting_documents: data.items[0]['ЮЛ']['Адрес']['АдресПолн'],
				email: data.items[0]['ЮЛ']['E-mail'],
				use_for_pay: true
			}
		},
		save_user(){
			this.$http.post("/user/update", this.user, () => {
				console.log('success')
			})
		}
	}
}
</script>
<style scoped>
p{
  text-align: left;
}
.v_email_updated_massage {
	text-align: left;
	color: green;
}

.v_err_updated_massage {
	text-align: left;
	color: #b01111;
}

.mb26 {
	margin-bottom: 26px;
}

.top {
	margin-bottom: 24px;
}

.block_service {
	display: flex;
	align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.history_title {
	font-style: normal;
	font-weight: 800;
	font-size: 28px;
	line-height: 130%;
	color: #404247;
	text-align: left;
	margin-bottom: 8px;
}

.desc {
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 150%;
	color: #55575C;
	text-align: left;
	margin-bottom: 16px;
}

.ok {
	margin-top: 30px;
	background: #E8F7F4;
	width: 100%;
	border-radius: 8px;
	padding: 16px 24px;
}

.btn_style {
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 120%;
	color: #2B2D33;
	background: #FEE600;
	border-radius: 8px;
	padding: 13px;
	margin-top: 40px;
	cursor: pointer;
}

.btn_style_1 {
	background: #FEE600;
}

.btn_style_2 {
	background: none;
	border: 1px solid #FEE600;
}

.history {
	margin: 8px auto 0 auto;
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 120%;
	color: #00809C;
	cursor: pointer;
	position: relative;
	width: max-content;
}

.history:after {
	content: '';
	display: block;
	height: 1px;
	width: 100%;
	background: #00809C;
	position: absolute;
	bottom: -4px;
}

.history_service {
    /*margin: 0 0 15px 0;*/
    margin: 0;
}

.save {
	background: #FFFFFF;
	border: 1px solid #FEE600;
	border-radius: 8px;
	padding: 9px 20px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	margin-top: 16px;
	line-height: 110%;
	color: #2B2D33;
	max-width: max-content;
	cursor: pointer;
}

.top {
	display: flex;
	flex-direction: column;
}

.inform {
	display: flex;
}

.bg_card_services{
	background: #FFFFFF;
	width: 49%;
	padding: 24px;
	min-width: 600px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 8px 24px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	margin-bottom: 30px;
}

.left {
	background: #FFFFFF;
	padding: 24px;
	min-width: 600px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 8px 24px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	/*width: 50%;*/
	width: 49%;
	height: max-content;
	margin-bottom: 50px;
}

.content {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.right {
	width: 50%;
	padding-left: 32px;
}

h1 {
	margin-bottom: 24px;
	font-style: normal;
	font-weight: 800;
	font-size: 28px;
	line-height: 130%;
	color: #404247;
}

h2 {
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	line-height: 130%;
	color: #404247;
	text-align: left;
	margin-top: 32px;
	margin-bottom: 24px;
}

.label {
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	color: #404247;
	text-align: left;
	margin-bottom: 6px;
}

.balance {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.left .title {
	font-style: normal;
	font-weight: 500;
	font-size: 19px;
	line-height: 120%;
	color: #55575C;
	text-align: left;
}

.left .num {
	font-style: normal;
	font-weight: 800;
	font-size: 36px;
	line-height: 130%;
	color: #404247;
	text-align: left;
}

.alert {
	background: #FEF3EC;
	padding: 16px 24px;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	width: 100%;

}

.left_text {
	display: flex;
	align-items: center;
}

.left_text .text {
	margin-left: 18px;
}

.mmm_data {
	background: #FFFFFF;
	border: 1px solid #FEE600;
	border-radius: 8px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 110%;
	text-align: center;
	color: #2B2D33;
	padding: 7px 17px;
	cursor: pointer;
}

.ok .text {
	color: #008E7D;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 120%;
}

.additional_services {
	width: 100%;
}

.services_card_footer {
	display: flex;
	justify-content: space-between;
}

.services_card_head {
	display: flex;
	justify-content: flex-start;
}

.services_card_head p {
	font-style: normal;
	font-weight: 700;
	font-size: 17px;
	line-height: 140%;
	margin-left: 50px;
	margin-right: 40px;
	text-align: left;
}

.services_card_footer a {
	margin: 0;
	text-decoration: none;
	height: max-content;
}

.additional_services h3 {
	text-align: left;
	/*margin-bottom: 25px;*/
	font-size: 24px;
}

.text_bold {
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
}

@media (max-width: 820px) {
	.alert {
		flex-direction: column;
	}

	.mmm_data {
		margin-top: 15px;
	}
}

@media (max-width: 650px) {
	.left {
		margin: 15px;
		min-width: initial;
		width: 100%;
	}

	.right {
		width: 100%;
		padding: 15px;
	}
}

@media (max-width: 520px) {
	.inform {
		flex-direction: column;
	}

	.balance {
		margin-bottom: 15px;
	}

	.history_title {
		padding: 10px;
	}

	.desc {
		padding: 10px;
	}
}
</style>