<template>
	<div style="width: 100%; text-align: left">
		<div class="title_input">
			{{ titleInputs }}
		</div>
<!--		<div class="num">-->
		<div>
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "WrapperInputs",
	props: {
		titleInputs: String
	}
}
</script>

<style scoped>
.title_input {
	text-align: left;
    padding-left: 0px;
}

/*.num {*/
/*    margin-left: 8px;*/
/*    margin-right: 8px;*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    font-size: 15px;*/
/*    line-height: 120%;*/
/*    color: #404247;*/
/*    min-width: 40px;*/
/*}*/
</style>