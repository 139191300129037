import {getters} from './getters'
import {actions} from './actions'
import {mutations} from './mutations'

const state = {
    footer:{
      hide:true,
      data:[],
      page_type:"none",
      cb_refresh:false,
      cb_clear:false
    }
};

const namespaced = true;

export const notification = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
