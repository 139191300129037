<template>
	<div class="text-center">
		<v-dialog v-model="dialog" width="510">
			<template v-slot:activator="{ on, attrs }">
				<div v-bind="attrs" v-on="on" class="add">
					<Icon name="plus"/>
					<span>Добавить шаблон</span>
				</div>
			</template>

			<div @click="close()" class="close">
				<Icon name="close"/>
			</div>
			<v-card class="wrapper">
				<div class="modal_title">
					<span>Новый шаблон</span>
				</div>
				<div>
					<p class="modal_subtitle">Название</p>
					<v-text-field v-model="name" placeholder="Название шаблона"></v-text-field>
				</div>
				<div>
					<p class="modal_subtitle">Тэги</p>
					<v-btn text v-for="(item, i) in tags" :key="i" @click="addTag(item)">{{ item.name }}</v-btn>
				</div>
				<div>
					<p class="modal_subtitle">Текст</p>
					<v-textarea v-model="text" placeholder="Текст шаблона" counter error></v-textarea>
				</div>
				<div>
					<p class="modal_subtitle">Операторы</p>
					<v-select v-model="operators" :items="items" multiple></v-select>
				</div>

				<div class="add add_modal" @click="saveTemplate">
					<span>Отправить на согласование</span>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import Icon from '../../SetIcon.vue'

export default {
	props: ['callback_refresh', 'get_tags', 'show_dialog'],
	components: {
		Icon
	},
	methods: {
		close(){
			this.dialog = false
			this.$emit('close')
		},
		addTag(tag) {
			this.text += '{{' + tag.slag + '}}'
		},
		saveTemplate() {
			this.$http.post("/template/create", {
				name: this.name,
				text: this.text,
				operators: this.operators,
			}, data => {
				console.log(data)

			})
		}
	},
	data() {
		return {
			items: ['MTS', 'Tele2', 'Beeline', 'Megafon', 'Rostelecom'],
			tags: [],
			name: '',
			text: '',
			operators: [],
			dialog: false,
			count: 0,
			queue: 0,
			complete: 0,
			unique: 0,
			check_complete: 0,
			edit_popup: false,
			manual_phone: "",
			basename: "",
			tagName: '',
			tagSlag: '',
			rules: {
				required: value => !!value || 'Обязательное поле',
				min: v => v.length >= 8 || 'Минимум 8 символов',
				emailMatch: () => (`The email and password you entered don't match`),
			},
		}
	},
	watch: {
		show_dialog: function (val) {
			this.dialog = val

			if (val) {
				// this.id=this.edit_tag.id
				// this.params=this.edit_tag.item.params
				// this.basename=this.edit_base.item.name
			}
		},
		get_tags: function (val) {
			this.tags = val
		}
	}
}
</script>
<style scoped>

.add_modal {
    width: 100%;
    padding: 9px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 110%;
    color: #2B2D33;
}

.wrapper {
    padding: 32px;
}

.modal_subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    margin-bottom: 6px;
    text-align: left;
    color: #404247;
}

.add {
    border: none;
    padding: 12px 20px 12px 22px;
    background: #FEE600 !important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #2B2D33;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add .icon {
    margin-right: 11px;
}

.modal_title {
    padding: 0 !important;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #2B2D33;
}
</style>