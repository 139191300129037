<template>
	<div class="">
		<v-dialog :value="showModal" :width="width" :hide-overlay="!overlay">
			<!--			<template v-slot:activator="{ on, attrs }">-->
			<!--				<div v-bind="attrs" v-on="on" class="add">-->
			<!--					<Icon name="plus"/>-->
			<!--					<span>Создать отчет</span>-->
			<!--				</div>-->
			<!--			</template>-->
			<div @click="$emit('close')" class="close">
				<Icon name="close"/>
			</div>
			<v-card class="wrapper">
				<div class="modal_title">
					{{ title }}
				</div>

				<slot></slot>

				<v-btn
					style="margin-top: 15px;"
					color="#000000"
					plain
					:type="typeBtn"
					@click="$emit('clickBtn')"
					:disabled="disabledBtn"
				>
					{{ textBtn }}
				</v-btn>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Icon from '@/components/SetIcon.vue'

export default {
	name: "BaseModal",
	components: {Icon},
	props: {
		showModal: {
			type: Boolean,
			required: true,
		},
		title: String,
		textBtn: {
			type: String,
			default: 'Сохранить'
		},
		width: {
			type: [String, Number],
			default: '490'
		},
		overlay: {
			type: Boolean,
            default: true
		},
		disabledBtn: {
			type: Boolean,
            default: false
		},
		typeBtn: {
			type: String,
            default: 'text',
			validator: function(value) {
                return ['text', ''].includes(value)
            }
		}
	},
	emit: ['close', 'clickBtn'],
}
</script>

<style scoped>
.wrapper {
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal_title {
    padding: 0 !important;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #2B2D33;
}

.btn_save {
    margin: 0;
}
</style>