<template>
    <div class="render">
      <h1>Поддержка</h1>
      <h2>Вот наша <a href="/">почта</a>, напишите мы вас поддержим</h2>
    </div>
</template>

<script lang="js">
// @vue/component
export default {
  name: 'SupportView',

  components: {},

  mixins: [],

  props: {},

  data () {
    return {}
  },

  computed: {},

  watch: {},

  created () {},

  methods: {}
}

</script>
<style lang="sass" scoped>

</style>