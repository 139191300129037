<template>
    <div class="wrapper-pdf">
        pdf
    </div>
</template>
<script>

export default {

}
</script>